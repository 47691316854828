const { getType } = require('./getType')

/**
 * A megadott paraméter string típusú?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is string} A paraméter string?
 */
function isString (param) {
  return getType(param) === 'string'
}

module.exports.isString = isString
