const { is } = require('../../is/is')

const { nativeJSON } = require('./nativeJSON')
const { defaultStringifyReplacer } = require('./defaultStringifyReplacer')

/**
 * @callback TReplacer
 * @param {any} this
 * @param {string} key
 * @param {any} value
 * @returns {any}
 */

const SPACE_DEFAULT = 0

/**
 * Biztonságos stringify.
 * Ha nem tudná stringgé konvertálni, akkor is biztosan egy üres objectet jelző stringet kapunk vissza.
 * A függvény NEM object vagy tömb esetén is a fallback-et adja vissza!
 * @param {unknown} data - Az adat, amit stringify-olni akarunk.
 * @param {string} [fallback={}] - Fallback, ha nem sikerül valamiért.
 * @param {number} [space=SPACE_DEFAULT] - Hanyad space indentet akarunk.
 * @param {TReplacer} [replacer] - Opcionális függvény, ami az értékeket tudja átalakítani.
 * @returns {string} A JSON string.
 */
module.exports.stringify = function stringify (
  data,
  fallback = '{}',
  space = SPACE_DEFAULT,
  replacer = defaultStringifyReplacer
) {
  if (!is.object(data) && !is.array(data)) {
    return fallback
  }

  try {
    return nativeJSON.stringify(data, replacer, space)
  }
  catch (_error) {
    return fallback
  }
}
