const { getType } = require('./getType')

/**
 * A megadott paraméter nem undefined?
 * @template {unknown} T
 * @param {T} param - Az ismeretlen paraméter.
 * @returns {param is Exclude<T, undefined>} A megadott paraméter nem undefined.
 *
 */
function isDefined (param) {
  return getType(param) !== 'undefined'
}

module.exports.isDefined = isDefined
