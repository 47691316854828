/**
 * Két koordináta közti szög kiszámítása.
 * @param {number} x1 - Első X koordináta.
 * @param {number} y1 - Első Y koordináta.
 * @param {number} x2 - Második X koordináta.
 * @param {number} y2 - Második Y koordináta.
 * @returns {number} A két koordináta közti szög.
 */
module.exports.degreeBetweenTwoCoordinates = function degreeBetweenTwoCoordinates (x1, y1, x2, y2) {
  return Math.atan2(y1 - y2, x1 - x2) * 180 / Math.PI
}
