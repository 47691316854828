/**
 * @readonly
 * @enum {number}
 */
const PROBLEM_NUMBERS = {
  BadCategory:    1,
  BadDescription: 2,
  BadProductName: 4,
  BadPicture:     8
}

/**
 * @readonly
 * @enum {number}
 */
const VAT = {
  Books:   5,
  Normal: 27
}

module.exports = { PROBLEM_NUMBERS, VAT }
