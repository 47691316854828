const { random } = require('./random')

/**
 * Random szám generálása kivetéles lista segítségével.
 * @param {number} min - Minimum érték.
 * @param {number} max - Maximum érték.
 * @param {ReadonlyArray<number>} excepts - A kivetelek listája.
 * @returns {number} A generált véletlen szám.
 */
module.exports.randomExcept = function randomExcept (min, max, excepts) {
  const rand = random(min, max)

  if (!excepts.includes(rand)) {
    return rand
  }

  return randomExcept(min, max, excepts)
}
