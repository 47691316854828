const { encode } = require('./utils/encode')
const { decode } = require('./utils/decode')
const { merge }  = require('./utils/merge')

const Query = {
  encode,
  decode,
  merge
}

module.exports.Query = Query
