/**
 * Két pozíció közötti távolság kiszámítása.
 * @param   {number}  x1            - Első x koordináta.
 * @param   {number}  y1            - Első y koordináta.
 * @param   {number}  x2            - Második x koordináta.
 * @param   {number}  y2            - Második y koordináta.
 * @param   {boolean} [isRaw=false] - A „nyers” távolságot adja vissza?
 * @returns {number}                A két pozíció közötti távolság.
 */
module.exports.distanceBetweenTwoPosition = function distanceBetweenTwoPosition ( // eslint-disable-line max-params -- Legacy.
  x1, y1, x2, y2, isRaw = false
) {
  const dx = x2 - x1
  const dy = y2 - y1
  const dt = (dx * dx) + (dy * dy)

  if (isRaw) {
    return dt
  }

  return Math.sqrt(dt)
}
