/* globals window */

/**
 * Visszaadja, hogy a futtatókörnyezet böngésző-e vagy pedig nem.
 * Fontos, hogy mivel mi nem dolgozunk WebWorker-el, ezért arra nem tesztel.
 * @see {@link https://github.com/flexdinesh/browser-or-node/blob/master/src/index.js}
 * @returns {boolean} Böngészőben vagyunk?
 */
function isBrowser () {
  // eslint-disable-next-line custom/no-native-typecheck, no-restricted-syntax -- natív typeof vizsgálat kell, különben elszáll "window is not defined" hibával.
  return typeof window !== 'undefined' && typeof window.document !== 'undefined'
}

module.exports.isBrowser = isBrowser
