/* eslint-disable n/prefer-global/buffer */
/* globals globalThis */

const { isNode } = require('./isNode')
const { isInstanceOf } = require('./isInstanceOf')

/**
 * A Buffer csak node környezetben van.
 * @type {any}
 */
const Buffer = isNode() ? globalThis.Buffer : undefined

/**
 * A megadott paraméterről megmondja, hogy buffer-e vagy sem.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is Buffer} A megadott paraméter egy Buffer.
 */
module.exports.isBuffer = function isBuffer (param) {
  return isInstanceOf(param, Buffer)
}
