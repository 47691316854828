/**
 * Visszaadja a `value` object-ben található `key` kulcshoz tartozó értéket.
 * @template {Object} T
 * @param {T} value - A megadott object.
 * @param {string} key - Az `value`-hoz tartozó rekurzív kulcs.
 * @returns {T[keyof T] | undefined} A kulcshoz tartozó érték.
 */
module.exports.getObjectValuesByKey = function getObjectValuesByKey (value, key) {
  const keys = key.split('.')

  /**
   * @type {T[keyof T] | undefined}
   */
  // @ts-expect-error
  return keys.reduce((acc, curr) => {
    // @ts-expect-error
    return acc[curr]
  }, value)
}
