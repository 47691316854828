/* globals $ */

const XMAS_CATEGORY_IMAGE = 'https://webshop.balazs-diak.hu/custom/taneszkoz/image/data/2023xmas/xmas.png'

const CAT_ID = 'cat_836'

/** A karácsonyi design beállítása. */
export default function xmas (): void {
  const preload = new Image()

  // Csak akkor csináljunk bármit is, ha betöltöttük a képet.
  // Lehet a kép már nincs a helyén, töröltük, átneveztük, stb.
  preload.addEventListener('load', () => {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.left     = '0'
    div.style.bottom   = '0'

    const img = document.createElement('img')
    img.src    = XMAS_CATEGORY_IMAGE
    img.height = 70
    img.width  = 274

    div.appendChild(img)

    $(`#${ CAT_ID } .nav-link`).get(0)?.append(div)
  })

  preload.src = XMAS_CATEGORY_IMAGE
}
