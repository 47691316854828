const { is } = require('../../is/is')
const { URI } = require('../../URI/URI')
const { Number } = require('../../Number/Number')

/** @typedef {import('../definitions').IQueryParams} IQueryParams */
/** @typedef {import('../definitions').TQueryValue} TQueryValue */

/**
 * Az összes kulcs-érték páros parszolása egy stringből.
 * @example
 * ```js
 * // https://www.figma.com/design/8GTMEMapDMYHrtXJPRyFHP/Csillagsuli?node-id=0-1&node-type=canvas&t=EPkbxKB962VEvurz-0
 * // { node-id: '0-1', node-type: 'canvas', t: 'EPkbxKB962VEvurz-0' }
 * const query = Query.decode(window.location.href)
 *
 * // ?node-id=0-1&node-type=canvas&t=EPkbxKB962VEvurz-0
 * // { node-id: '0-1', node-type: 'canvas', t: 'EPkbxKB962VEvurz-0' }
 * const query2 = Query.decode(window.location.search)
 *
 * // { foo: 'bar', baz: 'qux' }
 * const query3 = Query.decode('?foo=bar&baz=qux')
 *
 * // { foo: 'bar', baz: 'qux' }
 * const query4 = Query.decode('foo=bar&baz=qux')
 * ```
 * @param {unknown} [path] - A string, amiből lesz az object.
 * @returns {IQueryParams} Az object, amiben a kulcs-értékek vannak.
 */
module.exports.decode = function decode (path) {
  if (
    !is.string(path) ||
    is.empty(path) ||
    path.trim() === '?'
  ) {
    return {}
  }

  const query = path.includes('?')
    ? path.split('?')[1]
    : path

  if (is.undefined(query) || query === '&') {
    return {}
  }

  const params = query
    .replace(/^\?/, '')
    .split('&')
    .map((keyWithValue) => keyWithValue
      .split('=')
      .map(URI.decodeComponent)
    )
    .reduce((/** @type {TMutable<IQueryParams>} */ acc, curr) => {
      const [ key, value ] = curr

      if (is.undefined(key)) {
        return acc
      }

      if (is.undefined(value)) {
        acc[key] = value

        return acc
      }

      /** @type {TQueryValue} */
      let res

      if ((/^[+-]?\d+$/).test(value)) { // number
        res = Number.parseInt(value)

        // Ha túl hosszú számot próbálunk parszolni, példa:
        // 605615007107000013604064, akkor abból 6.056150071070001e+23 lesz.
        // Ebben az esetben inkább stringként tartsuk meg.
        res = `${ res }` === value ? res : value
      }
      else if ((/^(?:false|true)$/).test(value)) { // boolean
        res = (value === 'true')
      }
      else {
        res = value
      }

      // A kulcs legvégén ott kell lennie a []-nak,
      // különben az nem számít tömb paraméternek.
      const [ keyPart, rem ] = key.split('[]')

      // Ha sikerült felbontani a "valami[]"-t, akkor a
      // a rem nem lesz undefined.
      const isArray = is.defined(rem)

      // Abban az esetben, ha egy olyan kulcs következik,
      // az iterációban, amely már korábban benne volt,
      // akkor azt tömbként kell kezelni.
      //
      // Ilyenkor 2 lehetőség áll fent:
      //    1) az érték primitív, tehát ekkor tömbbé kell alakítani,
      //    2) az érték alapból tömb, tehát csak pusholni kell bele.
      const already = acc[keyPart]

      // Ilyenkor nagyon egyszerű a történet, hiszen korábban
      // nem volt benne, így csak hozzá kell adni.
      if (!is.defined(already)) {
        // Itt fontos, ha tömb paraméterről van szó,
        // akkor már alapból egy tömböt veszünk fel.
        // Ellenkező esetben, egy egyelemű tömb legvégül
        // nem tömbként jelenne meg, hanem primitívként.
        const valueToInsert = isArray
          // Itt annyiban van egy kis okosítás még,
          // hogy ha üres sztring a res ÉS tömb paraméter,
          // akkor az azt jelenti, hogy nincs semmi megadva,
          // így egy üres tömböt kell felvenni.
          ? res === '' ? [] : [ res ]
          : res

        acc[keyPart] = valueToInsert

        return acc
      }

      // Ez a helyzet sem bonyolult, csak hozzáadjuk
      // az új elemet a tömbhöz.
      if (is.array(already)) {
        // @ts-ignore -- TODO: Krisztofer Dubois
        already.push(res)

        return acc
      }

      // Ebben az esetben pedig pedig „tömbösítjük”.
      acc[keyPart] = [ already, res ]

      return acc
    }, {})

  return params
}
