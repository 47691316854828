const { isInstanceOf } = require('./isInstanceOf')

/**
 * A megadott paraméter egy példányosított RegExp?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is RegExp} A paraméter RegExp?
 */
module.exports.isRegExp = function isRegExp (param) {
  return isInstanceOf(param, RegExp)
}
