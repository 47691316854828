/**
 * Véletlenszerű szám generálása.
 * @param   {number}  min                - Minimum érték.
 * @param   {number}  max                - Maximum érték.
 * @param   {boolean} [isFloating=false] - Lebegőpontos számot kell-e vissza adni.
 * @param   {number}  [toFixed=2]        - Mennyi szám legyen a tizedes pont után.
 * @returns {number}                     Generált szám.
 */
module.exports.random = function random (min, max, isFloating = false, toFixed = 2) {
  if (isFloating) {
    return parseFloat(((Math.random() * (max - min)) + min).toFixed(toFixed))
  }

  return Math.floor(Math.random() * ((max - min) + 1)) + min
}
