const { parse }              = require('./utils/parse')
const { stringify }          = require('./utils/stringify')
const { numericStringify }   = require('./utils/numericStringify')
const { strictNumericParse } = require('./utils/strictNumericParse')
const { prettyStringify }    = require('./utils/prettyStringify')
const { nativeJSON }         = require('./utils/nativeJSON')

const JSON = {
  parse,
  stringify,
  prettyStringify,
  numericStringify,
  strictNumericParse,
  native: nativeJSON
}

module.exports.JSON = JSON
