/* eslint-disable custom/no-empty-length -- Ezért van. :) */

const { isObject }    = require('./isObject')
const { isArrayLike } = require('./isArrayLike')

/**
 * Visszaadja, hogy az adott tömb, string vagy object üres-e.
 * @template {string | ArrayLike<any> | Object} T
 * @param {NonNullable<Exclude<T, number>>} param - Olyan paraméter, amiről meg tudjuk állapítani, hogy van-e tartalma vagy nincs.
 * @returns {boolean} Üres-é?
 */
module.exports.isEmpty = function isEmpty (param) {
  if (isArrayLike(param)) {
    return param.length <= 0
  }

  if (isObject(param)) {
    return Object.keys(param).length <= 0
  }

  return true
}
