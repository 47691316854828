/**
 * A megadott paraméter egy pozitív vagy negatív végtelen?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @param {'both' | 'negative' | 'positive' } [type='both'] - Milyen előjelű Infinity-t vizsgáljon.
 * @returns {param is Infinity} Végtelen szám?
 */
function isInfinite (param, type = 'both') {
  const isPositive = (

    param === Infinity ||
    param === Number.POSITIVE_INFINITY
  )

  const isNegative = param === Number.NEGATIVE_INFINITY

  if (type === 'both') {
    return isPositive || isNegative
  }

  if (type === 'positive') {
    return isPositive
  }

  if (type === 'negative') {
    return isNegative
  }

  return false
}

module.exports.isInfinite = isInfinite
