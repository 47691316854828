const { is } = require('../../is/is')

/**
 * Átparse-olja a megadott `value` értéket.
 * @example
 * '42' → 42
 * '42.42' → 42.42
 * '42.42.42' → 0
 * '42px' → 0
 * @param {unknown} value       - Az érték, amit át kell parse-olni.
 * @param {number} [fallback=0] - Az érték, amit visszaad, ha a parse nem sikerül.
 * @returns {number} A parse-olt érték.
 */
module.exports.parse = function parse (value, fallback = 0) {
  // eslint-disable-next-line no-restricted-syntax, no-restricted-globals -- Egyedül itt engedjük.
  const parsed = Number(value)

  // Sikerült számot parse-olni.
  if (is.number(parsed)) {
    return parsed
  }

  return fallback
}
