const { isNullable } = require('./isNullable')

/**
 * Visszaadja, hogy a megadott paraméter nem null és nem undefined-e.
 * @template {unknown} T
 * @param {T} param - Az ismeretlen paraméter.
 * @returns {T is NonNullable<T>} A paraméter nem null és nem undefined?
 */
module.exports.isNonNullable = function isNonNullable (param) {
  return !isNullable(param)
}
