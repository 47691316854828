/* eslint-disable custom/no-nested-ternary */

const { is } = require('../../is/is')

const { Number } = require('../../Number/Number')

const { defaultStringifyReplacer } = require('./defaultStringifyReplacer')

/**
 * Ha az érték numerikus, akkor számmá alakítjuk.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#the_replacer_parameter}
 * @param {unknown} _key - Az aktuális kulcs, nincs használatban.
 * @param {unknown} givenValue - Az aktuális érték.
 * @returns {unknown} Az eredeti vagy a számmá konvertált érték.
 */
module.exports.numericReplacer = function numericReplacer (_key, givenValue) {
  const value = defaultStringifyReplacer(_key, givenValue)

  const isHunTel = is.string(value)
    ? value.startsWith('+36') || value.startsWith('06')
    : false

  // Ha string, attól még lehet, hogy numerikus, számként küldjük fel.
  const res = isHunTel
    ? value
    : is.string(value)
      ? is.numeric(value)
        ? Number.parse(value)
        : value
      : value

  // Ha szám, akkor vizsgáljuk, hogy valid szám-e.
  if (
    is.number(res) &&
    (
      // Ha egy túl hosszú vagy túl rövid szám, akkor ki tud futni a max karakterszámból és akkor átalakítja azt.
      //
      // Pl.: 605615006230000013603200 → 6.0561500623e+23
      //
      res >= Number.MAX_SAFE_INTEGER ||
      res <= Number.MIN_SAFE_INTEGER ||
      //
      // Pl.: 01, ami JS-nek egy valid szám, akkor az legyen string.
      //
      (
        is.integer(res) &&
        (
          res !== 0 &&
          is.string(value) &&
          value.startsWith('0')
        )
      )
    )
  ) {
    return value
  }

  return res
}
