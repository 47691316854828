const { is } = require('../../is/is')

/**
 * Speciális értékek kezelése a stringify függvény hívásakor.
 * @example
 * ```js
 *  Infinity → 'Infinity'
 * -Infinity → '-Infinity'
 * ```
 * @param {unknown} _key - A nem használt kulcs.
 * @param {unknown} value - Az érték, amit vizsgálunk.
 * @returns {any} A normalizált Infinity.
 */
module.exports.defaultStringifyReplacer = function defaultStringifyReplacer (_key, value) {
  if (is.infinite(value, 'positive')) {
    return 'Infinity'
  }

  if (is.infinite(value, 'negative')) {
    return '-Infinity'
  }

  return value
}
