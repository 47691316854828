const { isDefined } = require('./isDefined')

/**
 * A megadott paraméter undefined?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is undefined} A megadott paraméter undefined.
 *
 */
function isUndefined (param) {
  return !isDefined(param)
}

module.exports.isUndefined = isUndefined
