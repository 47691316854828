const { numericReplacer } = require('./numericReplacer')
const { nativeJSON } = require('./nativeJSON')

/**
 * Olyan parse, ami a numerikus string értékeket számmá konvertálja.
 * @param {unknown} data - Az adat, amit stringify-olni akarunk.
 * @returns {any} A natív parse any-t ad vissza, így mi is.
 */
module.exports.strictNumericParse = function strictNumericParse (data) {
  // @ts-expect-error Hogy a parse bármit benyeljen.
  return nativeJSON.parse(data, numericReplacer)
}
