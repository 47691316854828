const { defaultParseReviver } = require('./defaultParseReviver')

const { nativeJSON } = require('./nativeJSON')

const { is } = require('../../is/is')

/**
 * @callback TReviver
 * @param {any} this
 * @param {string} key
 * @param {any} value
 * @returns {any}
 */

/**
 * Biztonságos parse-olás, mindenféleképp objectet kapunk vissza.
 * @template {TAnyObject} T
 * @param {unknown} data - A JSON, amiből objectet akarunk csinálni.
 * @param {any} fallback - Fallback, ha nem sikerülne.
 * @param {TReviver} [reviver] - Opcionális függvény, ami az értékeket tudja átalakítani.
 * @returns {T} Egy ismeretlen object.
 */
module.exports.parse = function parse (
  data,
  fallback = {},
  reviver = defaultParseReviver
) {
  // Ha nem szöveges, akkor tuti rossz.
  if (!is.string(data)) {
    console.log('\n[JSON][parse] nem string\n', data, '\n')

    return fallback
  }

  // Numerikus stringeket se engedjünk a parse-nak, mert számot generál belőle.
  if (is.numeric(data)) {
    return fallback
  }

  try {
    return nativeJSON.parse(data, reviver)
  }
  catch (_error) {
    console.log('\n[JSON][parse] error\n', _error, '\n')

    return fallback
  }
}
