/* eslint-disable max-lines */
import type {
  TSku, TWebshop, TDomains, TScripts,
  TSettings, TWebHooks, TImageFile,
  TProductImages,
  IProductExtend,
  TMergedCategories,
  TNonBdidactWebshop,
  TOrderProductsSummary,
  TProductExtendsResponse,
  TCategoriesWithProductsCount
} from '@common/ShoprenterAPI/definitions'

import type { PRODUCT_STATUS, PRODUCT_ORDERABLE } from '@common/ShoprenterAPI/consts'
import type { IPaginationOptions } from '@common/bootstrap/Paginator/Paginator'

import type { IPaginationResponse } from '@corp/website/definitions'

import type { VAT } from './consts'
import { PROBLEM_NUMBERS } from './consts'

export type { IPaginationOptions, IPaginationResponse }

export { WEBSHOPS, PRODUCT_STATUS, CATEGORY_STATUS, PRODUCT_ORDERABLE } from '@common/ShoprenterAPI/consts'

export const MAX_SKU_LIST_LENGTH = 1000

export const PRODUCT_NAME_MIN_LENGTH = 4
export const PRODUCT_DESC_MIN_LENGTH = 5

export type {
  TSku, TWebshop, TCategories, IProductExtend, TProductExtends,
  TMergedCategories, IProductDescription, TProductDescriptions,
  TOrderProductsSummary, TCategoriesWithProductsCount
} from '@common/ShoprenterAPI/definitions'

export type { ICustomError } from '@common/backend/definitions'

export type TSimpleSource = 'excel' | 'manual'
export type TSource = TSimpleSource | `copy:${ TWebshop }`

export const WEBSHOP_URLS: TReadonlyRecord<TWebshop, string> = {
  taneszkoz: 'https://webshop.balazs-diak.hu',
  bdro: 'https://bdidact.ro',
  bdovi: 'https://bdovi.hu'
}

// --- Routes

export enum EPendingProductRoute {
  UpdateProductDescription = '/api/pendingProduct/updateDescription',
  GetAllUndoneProducts     = '/api/pendingProduct/getAllUndone',
  UploadProductImages      = '/api/pendingProduct/uploadImages',
  GetAllDoneProducts       = '/api/pendingProduct/getAllDone',
  UploadProductData        = '/api/pendingProduct/uploadData',
  InsertProduct            = '/api/pendingProduct/insert',
  UpdateProduct            = '/api/pendingProduct/update',
  DeleteProduct            = '/api/pendingProduct/delete',
  GetOneProduct            = '/api/pendingProduct/get',
  GetAnalytics             = '/api/pendingProduct/getAnalytics',
  ImportExcel              = '/api/pendingProduct/importExcel',
  ExportExcel              = '/api/pendingProduct/exportExcel'
}

export enum EBdidactWebshopProductsRoute {
  GetAllProducts = '/api/bdidactWebshopProducts/getAllproducts'
}

export enum EBdidactPendingProductRoute {
  GetAllProducts = '/api/bdidactPendingProduct/getAllProducts',
  InsertProduct  = '/api/bdidactPendingProduct/insert',
  UpdateProduct  = '/api/bdidactPendingProduct/update',
  UploadProduct  = '/api/bdidactPendingProduct/uploadProduct',
  SetWrong       = '/api/bdidactPendingProduct/set-wrong',
  ImportExcel    = '/api/bdidactPendingProduct/importExcel',
  ExportExcel    = '/api/bdidactPendingProduct/exportExcel',
  ConflictRepair = '/api/bdidactPendingProduct/conflict-repair'
}

export enum ECategoryMatching {
  Update = '/api/categoryMatching/update'
}

export enum EWebshopOrdersRoute {
  GetAll = '/api/webshop-orders/get-all'
}

export enum EWebshopDomainsRoute {
  GetAll = '/api/webshop-domains/get-all'
}

export enum EWebshopScriptsRoute {
  GetAll = '/api/webshop-scripts/get-all'
}

export enum EWebshopSettingsRoute {
  GetAll = '/api/webshop-settings/get-all'
}

export enum EWebshopHooksRoute {
  GetAll = '/api/webshop-hooks/get-all'
}

export enum EWebshopsRoute {
  // Analytics
  GetCommonProductsAnalytics = '/api/webshops/getCommonProductsAnalytics',
  GetOrderProductsAnalytics  = '/api/webshops/getOrderProductsAnalytics',
  GetOrdersAnalytics         = '/api/webshops/getOrdersAnalytics',
  GetOrdersChart             = '/api/webshops/getOrdersChart',

  // Products
  CopyToPendingProducts      = '/api/webshops/copyToPendingProducts',
  DeleteProductBySku         = '/api/webshops/deleteProductBySku',
  GetProductBySku            = '/api/webshops/getProductBySku',
  MassRanOutUpdate           = '/api/webshops/massRanOutUpdate',
  MassStateUpdate            = '/api/webshops/massStateUpdate',
  UpdateProducts             = '/api/webshops/updateProducts',
  CopyProduct                = '/api/webshops/copyProduct',
  GetAllProducts             = '/api/webshops/getAllProducts',
  GetProductsByInnerIds      = '/api/webshops/getProductsByInnerIds',
  GetProductTaxesByHrefs     = '/api/webshops/getProductTaxesByHrefs',
  ExportProducts             = '/api/webshops/exportProducts',

  // Waiting list requests
  InsertWaitingListRequest   = '/api/webshops/insertWaitingListRequest',
  GetPageWaitingListRequests = '/api/webshops/getPageWaitingListRequests',

  // Categories
  GetAllCategories           = '/api/webshops/categories/getAll',

  // Images
  CopyProductImage           = '/api/webshops/images/copyProductImage',
  InsertImages               = '/api/webshops/images/insertImages',
  GetAllImagesBySku          = '/api/webshops/images/getAllBySku',
  UpdateProductImages        = '/api/webshops/images/updateProductImages',
  GetImagesAsBase64          = '/api/webshops/images/getImagesAsBase64'
}

export enum EProblematicProductsRoute  {
  BulkInsert  = '/api/problematicProducts/bulk-insert',
  ChangeState = '/api/problematicProducts/change-state',
  DeleteById  = '/api/problematicProducts/delete',
  GetById     = '/api/problematicProducts/getById',
  Modify      = '/api/problematicProducts/modify',
  GetAll      = '/api/problematicProducts/getall',
  Insert      = '/api/problematicProducts/insert'
}

export enum EWebshopCategoriesRoute {
  GetAll = '/api/webshopCategories/getall'
}

export enum EWebshopManufacturersRoute {
  GetAll    = '/api/webshopManufacturers/getall',
  GetByName = '/api/webshopManufacturers/getByName',
  Update    = '/api/webshopManufacturers/update',
  Insert    = '/api/webshopManufacturers/insert'
}

export enum EVectoryProductsRoute {
  Insert               = '/api/vectoryProducts/insert',
  Reset                = '/api/vectoryProducts/reset',
  GetAll               = '/api/vectoryProducts/getAll',
  GetBySku             = '/api/vectoryProducts/get-by-sku',
  GetStock             = '/api/vectoryProducts/get-stock',
  ImportStock          = '/api/vectoryProducts/import-stock',
  UpdateStock          = '/api/vectoryProducts/update-stock',
  UpdateProductSharing = '/api/vectoryProducts/update-product-sharing'
}

export enum EMissingProductsRoute {
  GetAll = '/api/missingProducts/getAll',
  Insert = '/api/missingProducts/insert'
}

export enum EActivitiesRoute {
  GetLatestByActivity = '/api/activities/getLatestByActivity'
}

export type TRoute = (
  EBdidactWebshopProductsRoute |
  EBdidactPendingProductRoute  |
  EWebshopManufacturersRoute   |
  EProblematicProductsRoute    |
  EWebshopCategoriesRoute      |
  EVectoryProductsRoute        |
  EMissingProductsRoute        |
  EWebshopSettingsRoute        |
  EWebshopScriptsRoute         |
  EPendingProductRoute         |
  EWebshopDomainsRoute         |
  EWebshopHooksRoute           |
  ECategoryMatching            |
  EActivitiesRoute             |
  EWebshopsRoute
)

// --- PendingProduct

export enum EPendingProductState {
  /** Feltöltve a táblázatba, de nem a webshopba. */
  Added = 0,

  /**
   * Termék feltöltve webshop(ok)ba, DE MÉG NINCS KÉPE! Ezt még nem vesszük késznek!
   * Ez az érték BDidact esetén a KÉSZ.
   */
  Uploaded = 1,

  /** Termékhez kép is hozzá lett adva. Ilyen értéke nincs a BDidact-nak. */
  Done = 2
}

/** A legelső feltöltéskor ezek az adatok kellenek. */
export interface IPendingProduct {
  readonly sku:          TSku
  readonly name:         string
  readonly manufacturer: string
  readonly price:        string
  readonly status:       number
  readonly orderable:    number
  readonly vat:          VAT
}

/** Az adatbázisban ilyen struktúrával mentjük a termékeket. */
export interface IPendingDbProduct extends IPendingProduct {
  readonly state:               EPendingProductState
  readonly adder:               string
  /** Mikor lett hozzáadva a rendszerhez. */
  readonly addedAt:             string
  readonly uploadedAt:          string | null
  readonly uploader:            string | null
  readonly taneszkozCategories: string | null
  readonly bdoviCategories:     string | null
  readonly description:         string | null
  /** Milyen forrásból lett hozzáadva a rendszerhez. */
  readonly source:              TSource
}

export type TUploadedProduct = Omit<{
  [P in keyof IPendingDbProduct]: NonNullable<IPendingDbProduct[P]>
}, 'state' | 'uploadedAt'>

export type TPendingDbProducts = readonly IPendingDbProduct[]

/** Összes termék lekérésénél ezt a választ küldjük. */
export interface IPendingProductsGetAllResponse {
  readonly products: TPendingDbProducts
}

/** Egy termék lekérésénél ezt a választ küldjük. */
export interface IPendingProductGetResponse {
  readonly product: IPendingDbProduct
}

/** Excel feltöltésekor ezt a választ fogjuk küldeni. */
export interface IPendingProductsImportExcelResponse {
  readonly newProductCount: number
  readonly ignoredProductCount: number
  readonly updatedDescriptionCount: number
}

/** A webshopokban lévő kategóriák, a webshop nevével namespacelve. */
export type TWebshopCategories = Record<TWebshop, TMergedCategories>

export interface ICategoriesGetAllResponse {
  readonly categories: TWebshopCategories
}

export interface IPendingProductCategory {
  readonly hashIds: string[]
  readonly names:   string[]
}

/** Ezeket a paramétereket várja a webshopba való feltöltéshez. */
export interface IPendingProductUploadData {
  readonly sku:           TSku
  readonly imageCount:    number
  readonly description:   string
  readonly taneszkoz:     IPendingProductCategory
  readonly bdovi:         IPendingProductCategory
  readonly needToBdidact: boolean
}

/** A menüpontok közötti navigációra szolgáló enum. */
export type TNavigate = (
  'pendingProducts'            |
  'uploadedProducts'           |
  'analytics'                  |
  'webshopProducts'            |
  'webshopCategories'          |
  'problematicProducts'        |
  'missingProducts'            |
  'webshopManufacturers'       |
  'bdidactPendingProducts'     |
  'bdidactWebshopProducts'     |
  'vectoryProducts'            |
  'webshopWaitingListRequests' |
  'webshopDomains'             |
  'webshopScripts'             |
  'webshopSettings'            |
  'webshopHooks'
)

export const PENDING_PRODUCTS_EXCEL_COLUMNS = [
  'Cikkszám', 'Cikknév', 'Bruttó ár', 'Leírás', 'ÁFA', 'Gyártó', 'Rendelhetőség'
] as const

export const PEDING_PRODUCTS_IMPORT_EXCEL_COLUMNS = [
  ...PENDING_PRODUCTS_EXCEL_COLUMNS,
  'Hozzáadta',
  'Hozzáadás dátuma',
  'Forrás'
]

/** A román webshophoz importálható excel fejléce. */
export const BDIDACT_PENDING_PRODUCTS_IMPORT_EXCEL_COLUMNS = [
  'Cikkszám', 'Cikknév', 'Leírás'
] as const

/** A román webshop termékek excel exportjának fejléce. */
export const BDIDACT_PENDING_PRODUCTS_EXPORT_EXCEL_COLUMNS = [
  'Cikkszám', 'Román terméknév', 'Magyar terméknév', 'Román leírás', 'Magyar leírás'
] as const

type TPendingProductsExcelColumn = typeof PENDING_PRODUCTS_EXCEL_COLUMNS[number]

/* eslint-disable @typescript-eslint/naming-convention */
export interface IBdidactPendingProductExcelRow {
  /** DB: description. */
  readonly Leírás: string

  /** DB: sku. */
  readonly Cikkszám: string

  /** DB: name. */
  readonly Cikknév: string
}

export type TBdidactPendingProductExcelRows = readonly IBdidactPendingProductExcelRow[]

/** Termékimportálás excel fájlból. Erre az interface-re illeszkednek a sorok. */
export interface IPendingProductExcelRow extends IBdidactPendingProductExcelRow {
  /** DB: orderable. */
  readonly Rendelhetőség: PRODUCT_ORDERABLE

  /** DB: price. */
  readonly 'Bruttó ár': number

  /** DB: vat. */
  readonly ÁFA: VAT

  /** DB: manufacturer. */
  readonly Gyártó: string
}
/* eslint-enable @typescript-eslint/naming-convention */

// Ha a tömbben definiált oszlopaink nevei nem egyeznek az IProductExcelRow kulcsaival,
// akkor never-t kapunk és helyben hibát fog dobni a TS.
type TExcelValidator<T> = T extends Record<TPendingProductsExcelColumn, unknown> ? IPendingProductExcelRow : never

export type TPendingProductProductExcelRows = readonly TExcelValidator<IPendingProductExcelRow>[]

export type TSkuList = readonly (number | string)[]

export interface IWebshopsMassStateUpdateRequest {
  readonly skuList: TSkuList
  readonly status: PRODUCT_STATUS
}

/** Tömeges termék állapotváltozás után visszaküldött adat. */
export type TWebshopsMassStateUpdateResponse = Record<TWebshop, number>

/** Tömeges kifutónak jelöléshez szükséges adat. */
export interface IWebshopsMassRanOutRequest {
  readonly skuList: TSkuList
}

/** Tömeges terméklekérdezéshez szükséged adat. */
export interface IWebshopsGetAllRequest {
  readonly skuList: TSkuList
}

/** Ez query request-ben megy. */
export interface IWebshopsExportProductsRequest {
  readonly skuList: string
}

/** Másoláskor küldendő request adat. */
export interface IWebshopsCopyProductRequest {
  /** A másolandó termék cikkszáma. */
  readonly sku: TSku
  /** Melyik webshopba akarjuk másolni? */
  readonly copyTo: TWebshop
  /** Milyen kategóriákba tartozik? */
  readonly category: IPendingProductCategory
}

/** Törléskor küldendő request adat. */
export interface IWebshopsDeleteBySkuRequest {
  /** Melyik terméket akarjuk törölni? */
  readonly sku: TSku
  /** Melyik webshopból akarjuk törölni? */
  readonly webshop: TWebshop
}

export interface IWebshopsPendingProductRequest {
  readonly sku: TSku
}

export interface IWebshopInsertImagesRequest {
  readonly sku: TSku
  readonly webshop: TNonBdidactWebshop
}

export interface IWebshopGetAllImagesBySkuRequest {
  readonly sku: TSku
}

export interface IWebshopGetImagesAsBase64Request {
  readonly sku: TSku
  readonly webshop: TWebshop
}

export interface INormalizedImageFile extends Omit<TImageFile, 'filePath'> {
  /** A kép kiterjesztése. */
  readonly ext: string

  /** Az eredeti filePath szigorúan könyvtár/fileName volt, mi csak a fájlnevet küldjük. */
  readonly filePath: string
}

export type TNormalizedImageFiles = readonly INormalizedImageFile[]

export interface IWebshopGetImagesAsBase64Response {
  readonly imageFiles: TNormalizedImageFiles
}

export interface IWebshopUpdateProductImagesRequest {
  readonly sku: TSku
  readonly webshop: TNonBdidactWebshop
  readonly productImages: readonly string[]
}

export interface IWebshopCopyProductImageRequest {
  readonly sku: TSku
  readonly fromWebshop: TNonBdidactWebshop
  readonly toWebshop: TNonBdidactWebshop
  readonly productImage: string
}

export interface IWebshopGetProductsByInnerIdsRequest {
  readonly data: Partial<TReadonlyRecord<TWebshop, readonly number[]>>
}

export interface IWebshopGetProductTaxesByHrefsRequest {
  readonly webshop: TWebshop
  readonly taxHrefs: readonly string[]
}

export interface IProductProblemFields {
  taneszkozProblems: number
  bdoviProblems: number
}

export interface IBaseProductProblem extends IProductProblemFields {
  readonly sku: TSku
  readonly description: string
}

/** Problémás termékek. */
export const PROBLEM_SOURCES = [
  'badCategory',
  'badDescription',
  'badProductName',
  'badPicture'
] as const

export type TProblemFlags = typeof PROBLEM_SOURCES[number]

type TGeneratedProblemFields = TReadonlyRecord<`${ TWebshop }_${ TProblemFlags }`, number>

export enum EProblematicProductsState {
  /** Feldolgozandó. */
  Waiting = 1,

  /** Módosítva, jóváhagyásra várva. */
  Updated = 2,

  /** Jóváhagyva. */
  Closed = 3
}

export interface IProblematicProduct extends IBaseProductProblem, TGeneratedProblemFields {
  readonly id: number
  readonly adderId: number
  readonly state: EProblematicProductsState
  readonly createdAt: string
  readonly problemFlags: number
  readonly source: string
}

export type TProblematicProducts = readonly IProblematicProduct[]

export interface IVectoryProduct {
  readonly sku: TSku
  readonly stock: number
  readonly createdAt?: string
  readonly lastUpdatedAt?: string
}

export type TVectoryProducts = readonly IVectoryProduct[]

export interface IVectoryProductsGetAllProductsRequest extends IBaseGetAllRequest {}
export interface IVectoryProductsGetAllProductsResponse extends IPaginationResponse<TVectoryProducts> {
  activities: {
    latestStockImport: IActivity | null
    latestPssStockUpdate: IActivity | null
  }
}

export interface IVectoryProductsGetStockRequest {
  readonly skuList?: readonly string[]
}

export interface IVectoryProductsGetStockResponse {
  readonly inventory: TProductsInventory
}

export interface IVectoryProductsUpdateStockRequest {
  readonly sku: TSku
  readonly stock: number
}

/** Vectory termékek és készletlista frissítése. */
export type TVectoryProductsInsertRequest = TSkuList

export type TWebshopGetAllImagesBySkuResponse = Record<TNonBdidactWebshop, TProductImages | null>

export interface ICopyProduct {
  /** A termék cikkszáma. */
  readonly sku: TSku
  /** Melyik webshopból lett átmásolva. */
  readonly source: TWebshop
  /** Ki volt a másoló? */
  readonly uploader: string
  /** Mikor lett átmásolva. */
  readonly uploadedAt: string
}

export type TCopyProducts = readonly ICopyProduct[]

export type TWebshopsResponse = {
  /** Másolt termékek adatai. */
  readonly copies: TCopyProducts

  /** Azok a cikkszámok, amik mindkét webshopban megtalálhatóak. */
  readonly commonSkuList: TSkuList

  /** A Shoprenter webshopokban nem található termékek cikkszámlistája. */
  readonly notFoundSkuList: TSkuList

  /**
   * Azoknak a termékeknek a cikkszámlistája, amik eltérnek a felküldött cikkszámokkal.
   * Ilyen lehet például, ha kis- és nagybetű eltérés van.
   * A Shoprenter megtalálja a terméket, visszaadja, de nincs meg az 1:1 egyezés.
   */
  readonly differentSkuList: TSkuList

  /** A rendszerünkből, a pendingProducts-ból feltöltött termékek adatai. */
  readonly uploads: TPendingDbProducts

  /** Azok a cikkszámok, amik nincsenek a Vectory WEB-es termékek között. */
  readonly notInVectorySkuList: TSkuList

  readonly vectoryProducts: TVectoryProducts

  /** Problémásnak jelölt termékek adatai. */
  readonly problematics: TProblematicProducts

  /** A webshoptermékek. */
  readonly webshopProducts: Record<TWebshop, TProductExtendsResponse>
}

export type TProductExtendWithCategories = IProductExtend & { categories: TMergedCategories }

export interface IWebshopsGetBySkuResponse {
  readonly problematicProduct: IProblematicProduct | null
  readonly hasFoundInVectory: boolean
  readonly taneszkozProduct: TProductExtendWithCategories | undefined
  readonly bdoviProduct: TProductExtendWithCategories | undefined
  readonly bdroProduct: TProductExtendWithCategories | undefined
  readonly stock: number
  readonly imageProblems: Record<TWebshop, Record<string, string[]>>
}

// Ez most csak alias.
export interface IWebshopsCopyProductResponse extends IWebshopsGetBySkuResponse {}

export interface IWebshopsStateUpdateProduct {
  readonly id: string
  readonly status: number // Ez itt PRODUCT_STATUS volt, de a Validator azt használva is number-t ad vissza.
}

export interface IPendingProductsFilters {
  readonly adder: string
  readonly search: string
}

export interface IPendingProductsOrder {
  readonly order: string // 'name' | 'sku' | 'hasStock'
  readonly sort: string // 'asc' | 'desc'
}

export interface IPendingProductsOptions {
  readonly filters: IPendingProductsFilters
  readonly order: IPendingProductsOrder
}

export interface IBdidactPendingProductsFilters extends IPendingProductsFilters {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  translateState: EBdidactPendingProductTranslateState
}

export interface IBdidactPendingProductsOptions extends Exclude<IPendingProductsOptions, 'filters'> {
  readonly filters: IBdidactPendingProductsFilters
}

export interface IBaseGetAllRequest {
  readonly pagination: IPaginationOptions
}

export interface IPendingProductsGetAllUndoneRequest extends IBaseGetAllRequest {
  readonly options: IPendingProductsOptions
}

export interface IProblematicProductsFilters {
  readonly startDate: string
  readonly endDate: string
  readonly webshop: string
  readonly state: EProblematicProductsState
}

export interface IProblematicGetAllRequest extends IBaseGetAllRequest {
  readonly filters: IProblematicProductsFilters
}

export interface IWebshopCategoriesGetAllRequest {
  readonly webshop: TWebshop
}

export interface IWebshopDomainsGetAllRequest {
  readonly webshop: TWebshop
}

export interface IWebshopDomainsGetAllResponse {
  readonly domains: TDomains
}

export interface IWebshopScriptsGetAllRequest {
  readonly webshop: TWebshop
}

export interface IWebshopScriptsGetAllResponse {
  readonly scripts: TScripts
}

export interface IWebshopSettingsGetAllRequest  {
  readonly webshop: TWebshop
}

export interface IWebshopSettingsGetAllResponse {
  readonly settings: TSettings
}

export interface IWebshopHooksGetAllRequest {
  readonly webshop: TWebshop
}

export interface IWebshopHooksGetAllResponse {
  readonly hooks: TWebHooks
}

/** Az összes feldolgozandó termék DONE request-je ugyanaz, mint az UNDONE. */
export interface IPendingProductsGetAllDoneRequest extends IPendingProductsGetAllUndoneRequest {}

export interface IPendingProductsGetAllUndoneResponse extends IPaginationResponse<TPendingDbProducts> {}
export interface IPendingProductsGetAllDoneResponse extends IPendingProductsGetAllUndoneResponse {}
export interface IProblematicProductsGetAllResponse extends IPaginationResponse<TProblematicProducts> {}

interface ICategoryMatching {
  readonly innerWebshop: TWebshop
  readonly outerWebshop: TWebshop
  readonly innerId: number
  readonly outerId: number
  readonly outerHashId: string
}

export type TCategoryMatchings = readonly ICategoryMatching[]

export interface IWebshopCategoriesGetAllResponse {
  readonly categories: TCategoriesWithProductsCount
  readonly matching: TCategoryMatchings | null
}

/** Egy feltöltött (de még nem webshopban lévő) termék módosítása. */
export interface IPendingProductUpdateDescriptionRequest {
  readonly description: string
}

/** A napi bontásos stat. */
interface IDailyAnalytics {
  readonly day: string
  readonly cnt: number
  readonly worker: string
}

/** Az összes napot tartalmazó tömb. */
export type TDailyAnalytics = IDailyAnalytics[]

/** Összefoglaló a napok alapján. */
export interface IDigest {
  readonly total: number
  readonly workers: Record<string, number>
}

export interface IPendingProductsAnalytics {
  /** Feltöltés, napi bontásban. */
  readonly daily: TDailyAnalytics
  readonly digest: IDigest
  /** A feltöltők nevei. */
  readonly workers: readonly string[]
}

export interface IPendingProductsAnalyticsRequest {
  readonly createdAtStart: string
  readonly createdAtEnd: string
}

export interface IPendingProductsAnalyticsResponse {
  readonly added: IPendingProductsAnalytics
  readonly uploaded: IPendingProductsAnalytics
}

export type TOrder = 'count' | 'totalNet'

export interface IWebshopCommonProductsAnalyticsRequest {
  readonly order: TOrder
  readonly createdAtStart: string
  readonly createdAtEnd: string
}

export interface IWebshopAnalyticsRequest {
  readonly order: TOrder
  readonly createdAtStart: string
  readonly createdAtEnd: string
  readonly webshop: TWebshop
}

export interface IWebshopProductStats {
  readonly count: number
  readonly totalNet: number
  readonly totalGross: number
}

export interface IWebshopAnalytics extends IWebshopProductStats {
  readonly products: TOrderProductsSummary
}

export interface IWebshopAnalyticsResponse {
  readonly orders: IWebshopAnalytics
}

export interface IWebshopOrderAnalyticsRequest {
  readonly webshop: TWebshop
  readonly createdAtStart: string
  readonly createdAtEnd: string
}

export interface IWebshopOrderDailyQueryParams {
  readonly createdAt: string
}

export interface IWebshopOrderIntervalQueryParams {
  readonly createdAtMin: string
  readonly createdAtMax: string
}

export interface IWebshopOrderAnalytics extends IWebshopOrderIntervalQueryParams {
  readonly name: string
  readonly sum: number
  readonly count: number
}

export interface IWebshopOrderChartRequest  {
  readonly webshop: TWebshop
  readonly createdAtMin: string
  readonly createdAtMax: string
}

export interface IWebshopOrderChartData {
  readonly label: string
  sum: number
  count: number
}

export type TWebshopOrderChartResponse = IWebshopOrderChartData[]

export interface IWebshopCommonProduct extends IWebshopProductStats {
  /** A termék cikkszáma. */
  readonly sku: TSku
  /** A termék neve. */
  readonly name: string
  /** A taneszkoz.hu webshopból kinyert adatok. */
  readonly t: IWebshopProductStats
  /** A bdovi.hu webshopból kinyert adatok. */
  readonly o: IWebshopProductStats
}

export type TWebshopCommonProducts = readonly IWebshopCommonProduct[]

interface IWebshopSummary<T> extends IWebshopProductStats {
  readonly products: T
}

export interface IWebshopCommonProductsAnalyticsResponse {
  readonly orders: IWebshopSummary<TWebshopCommonProducts>
}

/** Egy webshop termék módosítható adatait tartalmazza. */
export interface IWebshopProductUpdateData {
  /** Termék azonosítója. */
  readonly id: string

  /** Termék cikkszáma. */
  readonly sku: TSku

  /** A termék neve. */
  readonly name: string

  /** A termék rövid leírása. */
  readonly shortDescription: string

  /** Termék rendelhetősége. */
  readonly orderable: PRODUCT_ORDERABLE

  /** Termék státusza. */
  readonly status: PRODUCT_STATUS

  /** Gyártó neve. */
  readonly manufacturer: string

  /** A termék SEO címe. */
  readonly metaTitle: string

  /** A termék SEO leírása. */
  readonly metaDescription: string

  /** Youtube videó kód. */
  readonly videoCode: string

  /** A kategóriák hashId listája. */
  categoryHashIds: string[]
}

/** A két webshop lehetséges adatait tartalmazza. */
export type TWebshopProductUpdateRequest = {
  readonly [key in TNonBdidactWebshop]: IWebshopProductUpdateData | null
}

export const PROBLEM_VALUES: Readonly<Record<TProblemFlags, { readonly name: string, readonly value: number }>> = {
  badCategory:    { name: 'Hibás kategória',    value: PROBLEM_NUMBERS.BadCategory    },
  badDescription: { name: 'Hibás termékleírás', value: PROBLEM_NUMBERS.BadDescription },
  badProductName: { name: 'Hibás terméknév',    value: PROBLEM_NUMBERS.BadProductName },
  badPicture:     { name: 'Hibás kép(ek)',      value: PROBLEM_NUMBERS.BadPicture     }
}

// Hány bittel (helyi értékkel) van eltolva a BDOvi-hoz tartozó flagek.
export const BDOVI_OFFSET = 20

export interface IProblemsInsert {
  readonly sku: TSku
  readonly problemFlags: number
  readonly description: string
}

interface IStateProperty {
  readonly text: string
  readonly badge: 'danger' | 'warning' | 'success'
}

export const PROBLEMATIC_PRODUCT_STATES: Record<keyof typeof EProblematicProductsState, IStateProperty> = {
  Waiting: { text: 'Javításra vár', badge: 'danger'   },
  Updated: { text: 'Javítva',       badge: 'warning'  },
  Closed:  { text: 'Jóváhagyva',    badge: 'success'  }
}

export enum EAnalyticsOrder {
  Count    = 'count',
  TotalNet = 'totalNet'
}

export interface IMissingProduct {
  /** Melyik webshop-ban szerepel a termék. */
  readonly webshop: TWebshop
  /** A termék cikkszáma. */
  readonly sku: TSku
}

export type TMissingDbProducts = IMissingProduct[]

export type TMissingProductsInsertRequest = IMissingProduct[]

export interface IMissingProductsGetAllResponse extends IPaginationResponse<TMissingDbProducts> {}

export interface IMissingProductsInsertResponse {
  readonly count: number
}

export interface IMissingProductsGetAllRequest extends IBaseGetAllRequest {}

interface IWebshopManufacturerWebshops {
  readonly webshops: Record<TWebshop, boolean>
}

export type TWebshopManufacturers = Record<string, IWebshopManufacturerWebshops>

export interface IWebshopManufacturersGetAllResponse {
  readonly manufacturers: TWebshopManufacturers
}

export interface IWebshopManufacturerGetByNameResponse {
  readonly manufacturer: IWebshopManufacturerWebshops & {
    readonly name: string
  }
}

export const MANUFACTURER_SKIP_NAME = 'Márka nélkül'

export enum EWebshopWaitingListRequestState {
  /** Feldolgozandó. */
  Waiting = 1,

  /** Módosítva, jóváhagyásra várva. */
  Updated = 2,

  /** Jóváhagyva. */
  Closed = 3
}

export interface IWebshopInsertWaitingListRequest {
  readonly webshop: TWebshop
  readonly customerId: number | null
  readonly productId: number
  readonly email: string | null
  readonly quantity: number
}

export interface IWebshopWaitingListProductData {
  readonly sku: TSku
  readonly productName: string
}

export interface IWebshopWaitingListRequest extends IWebshopInsertWaitingListRequest, IWebshopWaitingListProductData {
  readonly id: number
  readonly sku: TSku
  readonly state: EWebshopWaitingListRequestState
  readonly createdAt: string
  readonly updateAt: string | null
  readonly deleteAt: string | null
}

export type TWebshopWaitingListRequests = readonly IWebshopWaitingListRequest[]

export interface IWebshopWaitingListRequestsFilters {
  readonly webshop?: TWebshop
  readonly state?: EWebshopWaitingListRequestState
  readonly search: string
}

export interface IWebshopWaitingListRequestsGetPageRequest extends IBaseGetAllRequest {
  readonly filters: IWebshopWaitingListRequestsFilters
}

export interface IWebshopWaitingListRequestsGetPageResponse
  extends IPaginationResponse<TWebshopWaitingListRequests> {}

// ------------- BDIDACT

export enum EBdidactPendingProductTranslateState {
  /** Még nem volt egyszer sem lefordítva. */
  ToBeTranslated = 0,

  /** Már egyszer le volt fordítva. */
  AlreadyTranslated = 1,

  /** Már volt fordítva, de újra át kell nézni. */
  ToBeReviewed = 2,

  /** Hibásnak jelölve. */
  Wrong = 3
}

export interface IBdidactPendingProduct {
  readonly sku: TSku
}

export enum EBdidactPendingProductState {
  /** Hozzá lett adva a táblázathoz. */
  Added = 0,

  /** Fel lett tölve webshopba. */
  Uploaded = 1
}

export interface IBdidactPendingDbProduct extends IBdidactPendingProduct {
  readonly state: EBdidactPendingProductState
  readonly name: string
  readonly adder: string
  readonly addedAt: string
  readonly uploader: string
  readonly uploadedAt: string
  readonly description: string
  readonly source: TSimpleSource
  readonly huName: string
  readonly huDescription: string
  readonly translateState: EBdidactPendingProductTranslateState
}

export type TBdidactPendingDbProducts = readonly IBdidactPendingDbProduct[]

/** Összes termék lekérésénél ezt a választ küldjük. */
export interface IBdidactPendingProductsGetAllResponse {
  readonly products: TBdidactPendingDbProducts
}

/** Egy termék lekérésénél ezt a választ küldjük. */
export interface IBdidactPendingProductGetResponse {
  readonly product: IBdidactPendingDbProduct
}

/** Ha egy terméket szeretnénk webshopba feltölteni. */
export interface IBdidactPendingProductUploadData {
  readonly sku: TSku
  readonly name: string
  readonly description: string
}

/** Ha egy termék fordítását hibásnak jelöljük. */
export interface IBdidactPendingProductSetWrongRequest {
  readonly sku: TSku
}

export interface IBdidactPendingProductConflictRepairRequest {
  readonly sku: TSku
}

export interface IBdidactPendingProductsGetAllUndoneResponse extends IPaginationResponse<TBdidactPendingDbProducts> {}

export interface IWebshopCategoryMatchingUpdateRequest {
  readonly bdidactCategoryId: number
  readonly categoryHashId: string
}

export interface IWebshopManufacturersInsertRequest {
  /** A gyártó neve. */
  readonly name: string
}

export interface IWebshopManufacturersUpdateRequest {
  /** A gyártó régi neve. */
  readonly oldName: string

  /** A gyártó új neve. */
  readonly name: string
}

/** Liza kérésére fixálni kellett a stock1-et 10-re és nincs készletcsökkenés. */
export const DEFAULT_STOCK1_COUNT = '10'

/** Liza kérésére nem csökken a termékszám. */
export const DEFAULT_SUBTRACT_STOCK = '0'

export interface IBDidactWebshopProduct {
  readonly sku: TSku
  readonly stock: number
}

export type TBDidactWebshopProducts = readonly IBDidactWebshopProduct[]

export interface IBDidactWebshopProductsGetAllRequest extends IBaseGetAllRequest {
  readonly skuList: TSkuList
}

export interface IBDidactWebshopProductsGetAllResponse extends IPaginationResponse<TProductExtendsResponse> {
  readonly notFoundSkuList: TSkuList
}

export interface IProductsInventory {
  readonly sku: TSku
  readonly stock: number
}

export type TProductsInventory = readonly IProductsInventory[]

export interface IProductsInventoryStats {
  readonly allProducts: number
  readonly outOfStock: number
  readonly inStock: number
}

export type TActivity = (
  'importStock' |
  'pssUpdateStock' |
  'updateStock' |
  'pssUpdateOneStock'
)

export interface IActivity {
  readonly id: number
  readonly activity: TActivity
  readonly userName: string
  readonly createdAt: string
}

export type TActivities = readonly IActivity[]

export interface IOrderProduct {
  readonly name: string
  readonly sku: TSku
  readonly price: string
  readonly count: string
  readonly total: string
  readonly vat?: number
}

export type TOrderProducts = readonly IOrderProduct[]

export interface IOrderTotal {
  readonly name: string
  readonly valueText: string
  readonly value: string
  readonly type: string
}

type TOrderTotals = readonly IOrderTotal[]

export interface IOrderAddress {
  readonly firstname: string
  readonly lastname: string
  readonly company: string
  readonly address1: string
  readonly address2: string
  readonly city: string
  readonly postcode: string
  readonly zoneName: string
  readonly countryName: string
  readonly addressFormat: string
  readonly methodName: string
  readonly methodPrice: string
}

export interface IOrder {
  readonly innerId: string
  readonly createdAt: string
  readonly email: string
  readonly phone: string
  readonly comment: string
  readonly taxNumber: string

  readonly shipping: IOrderAddress
  readonly payment: IOrderAddress

  readonly orderProducts: TOrderProducts
  readonly orderTotals: TOrderTotals

  readonly status: string | null
  readonly total: string
  readonly currency: string
}

export type TOrders = readonly IOrder[]

export interface IVectoryProductStockChange {
  readonly sku: TSku
  readonly fromStock: number
  readonly toStock: number
  readonly createdAt: string
  readonly source: string
  readonly updaterName: string
}

export type TVectoryProductStockChanges = readonly IVectoryProductStockChange[]

export interface IVectoryProductStockChangesResponse {
  readonly actual: number
  readonly stockChanges: TVectoryProductStockChanges
}
