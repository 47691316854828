/* globals globalThis */

const { is } = require('../is/is')

/**
 * Mélységi klónozás, amely a megadott változó tartalmát
 * referencia alapú hivatkozás helyett lemásolja annak értékét rekurzívan.
 *
 * Ha támogatja az adott környezet, akkor a structuredClone-ot használja.
 *
 * Erre a függvényre azért van szükség, mert a JS out-of-the-box
 * referenciaként kezel bizonyos típusokat: object, tömb, Map (HashMap), dátum.
 * A primitívekkel nincs baj, azokat szépen érték szerint adja át. – Habár a string
 * nem számítana primitívnek, hiszen az egy char tömb, mégis a JS annak tartja.
 *
 * [DISCLAIMER]: mivel globális tiltva van Set és Map használat, ezért itt nincs
 * ez megvalósítva. Később, lehet, hogy lesz rá szükség, de akkor majd valaki implementálja. :).
 *
 * Egyelőre van benne bőven ts-ignore, de majd – egyszer :D – ez is fixelve lesz.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/structuredClone}
 * @template T
 * @param {T} value - A klónozni kívánt változó.
 * @returns {T} A klónozott érték.
 */
function cloneDeep (value) {
  /* eslint-disable no-restricted-globals -- Itt most engedjük a natívat. */
  if (is.function(globalThis.structuredClone)) {
    return globalThis.structuredClone(value)
  }
  /* eslint-enable no-restricted-globals */

  // Dátum.
  if (is.date(value)) {
    // @ts-ignore
    return new Date(value)
  }

  // Tömb.
  if (is.array(value)) {
    const newArray = value.map((v) => cloneDeep(v))

    // @ts-ignore
    return newArray
  }

  // Object.
  if (is.object(value)) {
    // eslint-disable-next-line no-restricted-globals -- Itt most engedjük a natívat.
    const clonedObject = Object.keys(value).reduce((/** @type {TAnyObject} */ acc, curr) => {
      // @ts-expect-error Meh.
      acc[curr] = cloneDeep(value[curr])

      return acc
    }, {})

    // @ts-ignore
    return clonedObject
  }

  return value
}

module.exports.cloneDeep = cloneDeep
