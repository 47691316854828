const { isNumber } = require('./isNumber')

/**
 * Visszaadja, hogy az adott szám lebegőpontos-e vagy sem.
 * A függvény nem tipusbiztosít.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {boolean} Lebegőpontos?
 */
function isFloat (param) {
  if (!isNumber(param)) {
    return false
  }

  // eslint-disable-next-line no-restricted-syntax -- Itt lehet. :)
  return param % 1 !== 0
}

module.exports.isFloat = isFloat
