const { cloneDeep } = require('../../utils/cloneDeep')
const { is }        = require('../../is/is')

// Ha egyben az egész Array-t húzzuk be, akkor circular dependency.
const { flatten } = require('../../Array/utils/flatten')

const { getEntries } = require('./getEntries')

/**
 * @see {@link https://stackoverflow.com/questions/49682569/typescript-merge-object-types}
 */

/**
 * @template T
 * @typedef {{ [K in keyof T]-?: ({} extends { [P in K]: T[K] } ? K : never) }[keyof T]} TOptionalPropertyNames
 */

/**
 * @template L
 * @template R
 * @template {keyof L & keyof R} K
 * @typedef {{ [P in K]: L[P] | Exclude<R[P], undefined> }} TSpreadProperties
 */

/**
 * @template T
 * @typedef {T extends infer U ? { [K in keyof U]: U[K] } : never} TId
 */

/**
 * @template L
 * @template R
 * @typedef {TId<Pick<L, Exclude<keyof L, keyof R>> & Pick<R, Exclude<keyof R, TOptionalPropertyNames<R>>> & Pick<R, Exclude<TOptionalPropertyNames<R>, keyof L>> & TSpreadProperties<L, R, TOptionalPropertyNames<R> & keyof L>>} TSpreadTwo
 */

/**
 * @template {ReadonlyArray<any>} A
 * @typedef {A extends [infer L, ...infer R] ? TSpreadTwo<L, TSpread<R>> : unknown} TSpread
 */

/**
 * @template {Object} A
 * @template {ReadonlyArray<Object>} B
 * @typedef {A & TSpread<B>} TReturnType
 */

/**
 * Össze-mergeli a megadott objektumokat.
 * @template {object} A
 * @template {ReadonlyArray<object>} B
 * @param {A} source     - Forrás.
 * @param {B} objects  - Amiket mergelni szeretnénk.
 * @returns {TReturnType<A, B>} Mergelt object.
 */
function mergeWith (source, ...objects) {
  // @ts-expect-error
  if (source === objects) {
    return source
  }

  // @ts-expect-error O_o
  if (!is.defined(objects) || is.empty(objects)) {
    // @ts-expect-error
    return source
  }

  if (
    is.array(source) &&
    is.array(objects)
  ) {
    // Abban az esetben, ha egy tömbökből álló tömböt adtunk át.
    const toBeMerged = objects.every(is.array)
      ? flatten(objects)
      : objects

    // És létrehozzuk a tömböt.
    // @ts-expect-error
    return [ ...source, ...toBeMerged ]
  }

  // Azért, hogy ne módosuljon az eredeti source object
  // csinálunk egy mélységi másolatot az objectről.
  const newObject = cloneDeep(source)

  const clonedObjects = is.array(objects) ? objects.map(cloneDeep) : []

  // 1. Ki kell szűrni, hogy melyek azok a kulcsok, amelyek benne
  // vannak az eredeti objectben és benne vannak legalább egy
  // mergeltetni kívánt objectben.
  //
  // 2. Azon kulcsokhoz tartozó értékeknek is bele kell kerülnie
  // amelyek a többi objectben vannak benne.
  const mergedObject = clonedObjects.reduce((acc, /** @type {TAnyObject} */curr) => {
    const bar = getEntries(curr).reduce((/** @type {TAnyObject} */ innerAcc, [ key, value ]) => {
      const currentlyAssignedValue = innerAcc[key]

      // Ha még nincs értéke, akkor megadjuk neki a jelenlegit.
      if (is.undefined(currentlyAssignedValue)) {
        innerAcc[key] = value

        return innerAcc
      }

      // Tömb esetén.
      if (is.array(currentlyAssignedValue) && is.array(value)) {
        innerAcc[key] = mergeWith(currentlyAssignedValue, ...value)

        return innerAcc
      }

      // Ha nem objectről beszélünk, akkor egyszerűen csak felülírjuk.
      if (!is.object(currentlyAssignedValue) && !is.object(value)) {
        innerAcc[key] = value

        return innerAcc
      }

      // Különben pedig, ha mindkettő értek egy object, akkor rekurzió!
      if (is.object(currentlyAssignedValue) && is.object(value)) {
        innerAcc[key] = mergeWith(currentlyAssignedValue, value)
      }

      return innerAcc
    }, acc)

    return bar
  }, newObject)

  // @ts-expect-error
  return mergedObject
}

module.exports.mergeWith = mergeWith
