const { is } = require('../../is/is')
const { getKeys } = require('./getKeys')

/**
 * Beállítja a `obj` object-ben található `key` kulcshoz tartozó értéket.
 * @template {Object} T
 * @param {T} obj - A megadott object.
 * @param {string} key - Az `obj`-hoz tartozó rekurzív kulcs.
 * @param {unknown} value - Beállítandó érték.
 */
module.exports.setObjectValueByKey = function setObjectValueByKey (obj, key, value) {
  const keys = key.split('.')

  // @ts-expect-error
  // eslint-disable-next-line no-unused-vars
  const res = keys.reduce((acc, actKey) => {
    // @ts-expect-error
    if (is.object(acc[actKey])) {
      // @ts-expect-error
      return acc[actKey]
    }
    // @ts-expect-error
    else if (getKeys(acc).includes(actKey)) {
      // @ts-expect-error
      acc[actKey] = value
    }

    return acc
  }, obj)
}
