const { is } = require('../../is/is')

const { decode } = require('./decode')

/**
 * @typedef {import('../definitions').IQueryParams} IQueryParams
 */

/**
 * Összefűz két query-t, legyen az string vagy object.
 * @param {unknown} query1 - Az egyik query.
 * @param {unknown} query2 - A másik Query.
 * @returns {IQueryParams} A mergelt query.
 */
module.exports.merge = function merge (query1, query2) {
  const isValidQ1 = is.string(query1) || is.object(query1)
  const isValidQ2 = is.string(query2) || is.object(query2)

  const validQ1 = isValidQ1 ? query1 : {}
  const validQ2 = isValidQ2 ? query2 : {}

  const q1 = is.string(validQ1) ? decode(validQ1) : validQ1
  const q2 = is.string(validQ2) ? decode(validQ2) : validQ2

  const merged = { ...q1, ...q2 }

  return merged
}
