const { isNumber } = require('./isNumber')
const { isEven }   = require('./isEven')

/**
 * Visszaadja, hogy egy szám páratlan e.
 * @param {number} num - A vizsgálandó szám.
 * @returns {boolean} Páratlan?
 */
function isOdd (num) {
  return isNumber(num) && !isEven(num)
}

module.exports.isOdd = isOdd
