import { is }     from '@common/is/is'
import { Object } from '@common/Object/Object'

const FORM_LABEL_TEXT_REPLACERS: TReadonlyRecord<string, readonly string[]> = {
  name: [
    'Intézmény név:', 'Vezetéknév', 'Keresztnév', 'Név:', 'Nume', 'Prenume'
  ],
  email: [
    'E-mail cím:', 'Email cím', 'Adresă de email', 'Adresa de e-mail:', 'E-mail cím'
  ],
  phone: [
    'Telefonszám:', 'Telefonszám', 'Număr de telefon', 'Numar de telefon:'
  ],
  taxNumber: [
    'Adószám', 'Cod fiscal'
  ],
  country: [
    'Szállítási ország', 'Țara de livrare'
  ],
  city: [
    'Város', 'Oraș'
  ],
  zip: [
    'Irányítószám', 'Cod poştal'
  ],
  address: [
    'Utca, házszám', 'Stradă, număr de casă'
  ]
}

/**
 * Form adatok összegyűjtése.
 * @param form - A megadott form.
 */
export default function getOfferFormValues (form: HTMLElement): TReadonlyRecord<string, string | null> {
  const formElements = Array.from(
    form.querySelectorAll('.form-element:not(.form-element-button):not(.form-element-captcha)')
  )

  // Nem található egy imput mező sem
  if (is.empty(formElements)) {
    return {}
  }

  const entries = Object.getEntries(FORM_LABEL_TEXT_REPLACERS)

  const result = formElements.reduce((acc, formElement) => {
    const textarea = formElement.querySelector('textarea')

    // Description lesz
    if (textarea) {
      acc.description = is.empty(textarea.value)
        ? null
        : textarea.value

      return acc
    }

    const label = formElement.querySelector('label')

    // Nem található a label
    if (!label) {
      return acc
    }

    const entry = entries.find(([ , value ]) => {
      return value.some((text) => {
        return label.textContent?.includes(text) ?? false
      })
    })

    // Az adott form-element-et nem kell menteni
    if (!entry) {
      return acc
    }

    const forAttr = label.getAttribute('for')

    if (!forAttr) {
      return acc
    }

    const field = formElement.querySelector<HTMLInputElement | HTMLSelectElement>(
      `input[id="${ forAttr }"], select[id="${ forAttr }"]`
    )

    if (!field) {
      return acc
    }

    // A mezőben nincs megadva érték
    if (is.empty(field.value)) {
      return acc
    }

    const [ key ] = entry

    const value = field.value && !is.empty(field.value)
      ? field.value
      : null

    acc[key] = acc[key] && !is.null(acc[key]) && !is.null(value)
      ? `${ acc[key] } ${ value }`
      : value

    return acc
  }, {} as Record<string, string | null>)

  return result
}
