/**
 * Visszaadja egy objectum értékeit.
 * @template T
 * @param {Record<any, T>} object - Az objektum értékei.
 * @returns {readonly T[]} Az object értékei.
 */
module.exports.getValues = function getValues (object) {
  // eslint-disable-next-line no-restricted-globals
  return Object.values(object)
}
