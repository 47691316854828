const { isNullable } = require('./isNullable')

/**
 * Visszaadja, hogy az adott példány a megadott osztályból származik-e.
 * @template {Function} T
 * @param {unknown} instance - A példány.
 * @param {T} instantiator - A példányosító osztály.
 * @returns {instance is InstanceType<T>} A példány a példányosítóból származik?
 */
function isInstanceOf (instance, instantiator) {
  if (isNullable(instantiator)) {
    return false
  }

  // eslint-disable-next-line custom/no-native-typecheck
  return instance instanceof instantiator
}

module.exports.isInstanceOf = isInstanceOf
