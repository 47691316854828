const { getType } = require('./getType')
const { isArray } = require('./isArray')

/**
 * A tömbben szerepel a megadott paraméter?
 * @throws Ha nem tömböt adtunk meg, akkor hibát dob.
 * @template T
 * @param {Readonly<T[]>} haystack - Az ismeretlen "szénakazal" paraméter, amiben keressünk.
 * @param {unknown} needle - A "tű", amit keresünk.
 * @param {number} [fromIndex] - Az index, ahonnan kezdve keressük a tömbben.
 * @returns {needle is T} Benne van?
 */
function isIncludes (haystack, needle, fromIndex) {
  if (!isArray(haystack)) {
    throw new TypeError(`A megadott paraméter nem tömb: ${ getType(haystack) }`)
  }

  // @ts-expect-error
  return haystack.includes(needle, fromIndex)
}

module.exports.isIncludes = isIncludes
