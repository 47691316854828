const { isNumber } = require('./isNumber')

/**
 * Visszaadja, hogy egy szám páros e.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {boolean} Páros?
 */
function isEven (param) {
  // eslint-disable-next-line no-restricted-syntax -- :)
  return isNumber(param) && param % 2 === 0
}

module.exports.isEven = isEven
