const { getObjectKeysRecursive } = require('./getObjectKeysRecursive')
const { setObjectValueByKey } = require('./setObjectValueByKey')

/**
 * Beállítja a `value` object-ben található összes értéket.
 * @template {Object} T
 * @param {T} obj - A megadott object.
 * @param {unknown} value - Beállítandó érték.
 */
module.exports.setObjectValues = function setObjectValues (obj, value) {
  const keys = getObjectKeysRecursive(obj)

  keys.forEach((key) => {
    setObjectValueByKey(obj, key, value)
  })
}
