import { WEBSHOPS } from '@common/ShoprenterAPI/consts'
import type { TSku, TWebshop } from '@common/ShoprenterAPI/definitions'
import type { IPaginationOptions } from '@common/bootstrap/Paginator/Paginator'

export type { IUserGetResponse } from '@corp/userService/definitions'

export { EBindValue } from '@corp/common/definitions'

export enum EOfferType {
  General = 0,
  Product = 1,
  Cart    = 2
}

export const OFFER_TYPES = [
  EOfferType.General,
  EOfferType.Product,
  EOfferType.Cart
] as const

export const SOURCES = [ ...WEBSHOPS ]

export type TSource = TWebshop

export enum EState {
  /** Az ajánlatkérés csak most érkezett meg. */
  Arrived = 0,
  /** Az ajánlatkérés fel lett dolgozva. */
  Processed = 1,
  /** Az ajánlatkérésben lévő termékek meg vannak rendelve. */
  Ordered = 2,
  /** Az ajánlatkérés le van zárva. */
  Closed = 3
}

export const STATES = [
  EState.Arrived,
  EState.Processed,
  EState.Ordered,
  EState.Closed
] as const

export const STATE_LABELS: TReadonlyRecord<EState, string> = {
  [EState.Arrived]: 'Beérkezett',
  [EState.Processed]: 'Feldolgozott',
  [EState.Ordered]: 'Megrendelt',
  [EState.Closed]: 'Lezárt'
}

interface IBaseOffer {
  /** Hol vették fel az ajánlatot. */
  readonly source: TSource
  /** Az ajánlatkérés típusa. */
  readonly type: EOfferType
  /** Az ajánlatkérő neve. */
  readonly name: string
  /** Az ajánlatkérő e-mail címe. */
  readonly email: string
  /** Az ajánlatkérő e-mail telefonszáma. */
  readonly phone: string | null
  /** Az ajánlatkérés leírása. */
  readonly description: string | null
  /** Az ajánlatkérő adószáma. */
  readonly taxNumber: string | null
  /** A szállítási ország. */
  readonly country: string | null
  /** A szállítási város. */
  readonly city: string | null
  /** A szállításhoz tartozó irányítószám. */
  readonly zip: string | null
  /** A szállításhoz tartozó cím. */
  readonly address: string | null
}

export interface IOffer extends IBaseOffer {
  /** A rendelés azonosítója. */
  readonly id: number
  /** Létrehozás dátuma. */
  readonly createdAt: string
  /** Ki módosította utoljára az adatot? */
  readonly modifiedBy: string | null
  /** Az ajánlatkérés állapota. */
  readonly state: EState
}

export type TOffers = ReadonlyArray<IOffer>

export interface IDigestOffer extends IOffer {
  /** Az árajánlathoz tartozó termékek azonosítói. */
  readonly productIds: ReadonlyArray<number>
}

export type TDigestOffers = ReadonlyArray<IDigestOffer>

export interface IInsertProduct {
  /** A termék azonosítója. */
  readonly productId: number
  /** Az adott árajánlatban ebből a termékből mennyit rendeltek. */
  readonly quantity: number
}

export type TInsertProducts = ReadonlyArray<IInsertProduct>

export interface IBaseOfferProduct extends IInsertProduct {
  /** A termék cikkszáma. */
  readonly sku: TSku
  /** A termék neve. */
  readonly name: string
  /** A termékhez tartozó leírás. */
  readonly description: string
  /** A termékhez tartozó nettó ár. */
  readonly netPrice: number
  /** A termékhez tartozó áfa értéke. */
  readonly taxRate: number
}

export interface IOfferProduct extends IBaseOfferProduct {
  /** Az ajánlatkérés azonosítója. */
  readonly offerId: number
}

export type TOfferProducts = ReadonlyArray<IOfferProduct>

export interface IDigestOfferProduct {
  /** A termék cikkszáma. */
  readonly sku: string
  /** A termék neve. */
  readonly name: string
  /** A termékből mennyi darab van az ajánlatkérésben. */
  readonly quantity: number
  /** A termék egységnyi nettó ára. */
  readonly netPrice: number
  /** A termék egységnyi bruttó ára. */
  readonly grossPrice: number
  /** A termék egységnyi nettó ára * `quantity`. */
  readonly netSumPrice: number
  /** Az áfa értéke. */
  readonly taxRate: number
  /** A termék egységnyi bruttó ára * `quantity`. */
  readonly grossSumPrice: number
}

export type TDigestOfferProducts = ReadonlyArray<IDigestOfferProduct>

export enum EOffersRoute {
  Insert      = '/ios/api/offers/insert',
  GetPage     = '/ios/api/offers/get-page',
  ChangeState = '/ios/api/offers/change-state/:id'
}

export type TOfferInsertRequest = TOptionalNullable<IBaseOffer> & { readonly products?: TInsertProducts }

export interface IOfferGetPageFilter {
  /** Ajánlatkérés webshop neve. */
  readonly source: TSource | null

  /** Ajánlatkérés típusa. */
  readonly type: EOfferType | null

  /** Ajánlatkérés állapota. */
  readonly state: EState | null
}

export interface IOfferGetPageRequest extends IPaginationOptions {
  readonly filter: IOfferGetPageFilter
}

export interface IOfferGetPageResponse {
  /** Az ajánlatkérések megjelenítési adatai. */
  readonly offers: TDigestOffers

  /** Összes ajánlatkérés darabszáma. */
  readonly count: number
}

export interface IOfferChangeStateRequest {
  /** Az ajánlatkérés új állapota. */
  readonly state: EState
}

export enum EOfferProductsRoute {
  GetByOfferId = '/ios/api/offer-products/get-by-offer-id/:id',
  ExportExcel = '/ios/api/offer-product/export-excel-by-offer-id/:id'
}

export interface IOfferProductsGetByOfferIdResponse {
  /** Az árajánlat. */
  readonly offer: IOffer

  /** A termékekhez tartozó megjelenítési adatok. */
  readonly products: TDigestOfferProducts

  /** Az összes termék nettó ára. */
  readonly netSumPrice: number

  /** Az összes termék bruttó ára. */
  readonly grossSumPrice: number
}
