const { isEven } = require('./isEven')

/**
 * A megadott paraméter primitív adattípusú?
 * @param {number} param - A szám paraméter.
 * @returns {param is boolean} A szám prím?
 */
module.exports.isPrime = function isPrime (param) {
  if (param <= 1) {
    return false
  }

  if (param === 2) {
    return true
  }

  if (isEven(param)) {
    return false
  }

  const largestPossibleDivisor = Math.floor(Math.sqrt(param)) - 1

  const isValid = !Array.from({ length: largestPossibleDivisor }, (_, i) => i + 2)
    .some((divisor) => param % divisor === 0)

  return isValid
}
