const { isNumber } = require('./isNumber')
const { isString } = require('./isString')

/**
 * A bemenő paraméter alapján megmondja, hogy az szám vagy sem.
 * @param {unknown} data - A bejövő paraméter, amit vizsgálunk.
 * @returns {data is string | number} Szám vagy sem.
 */
module.exports.isNumeric = function isNumeric (data) {
  if (isNumber(data)) {
    return true
  }

  if (isString(data)) {
    /**
     * A jQuery-nek van egy sokkal jobb megoldása, mint bármi más, amit eddig találtam.
     * @see {@link https://github.com/jquery/jquery/blob/bf48c21d225c31f0f9b5441d95f73615ca3dcfdb/src/core.js#L206}
     */
    // @ts-expect-error Ez most itt nem érdekel.
    // eslint-disable-next-line no-restricted-syntax -- Jó a natív.
    const result = (data - parseFloat(data) + 1) >= 0

    return result
  }

  return false
}
