/**
 * A megadott paraméter null?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is null} Null?
 */
function isNull (param) {
  // eslint-disable-next-line custom/no-native-typecheck, no-restricted-syntax
  return param === null
}

module.exports.isNull = isNull
