/**
 * Visszaadja, hogy a megadott paraméter true értékű vagy sem.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is true} A megadott érték true?
 */
function isTruthy (param) {
  return param === true
}

module.exports.isTruthy = isTruthy
