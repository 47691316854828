const OBJ = {}

/**
 * A typeof operátor egy halott dolog.
 * Angus Croll's article on "Fixing the JavaScript typeof operator".
 * @example
 * 'myString' == string
 * window == window (instead of object)
 * [] == array (instead of object)
 * {} == object
 * Array == function
 * Object == function
 * new Date() == date (instead of object)
 * new Error() == error (instead of object)
 * /a-z/ == regexp (instead of object)
 * null == null (instead of object)
 * JSON == json (instead of object)
 * navigator == navigator (instead of object)
 * screen == screen (instead of object)
 * history == history (instead of object)
 * location == location (instead of object)
 * document == htmldocument (instead of object)
 * console == console (instead of object)
 * console.memory == memoryinfo (instead of object)
 * Element == function
 * Date == function
 * NodeList == function
 * Attr == function
 * NaN == number
 * true|false == boolean
 * RegExp == function
 * Infinity == number
 * arguments == arguments (instead of object)
 * document.createElement('style') == htmlstyleelement (instead of object)
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {string} A paraméter típusa.
 */
function getType (param) {
  const o = (OBJ).toString.call(param)
  const m = o.match(/\s([A-Za-z]+)/)

  if (!m || !m[1]) {
    return ''
  }

  const r = m[1].toLowerCase()

  return r
}

module.exports.getType = getType
