const { isFunction } = require('./isFunction')
const { isNumber } = require('./isNumber')

/**
 * Egész számról van szó?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is number} A megadott érték szám és egész szám.
 */
function isInteger (param) {
  if (!isNumber(param)) {
    return false
  }

  if (
    param === Number.MAX_SAFE_INTEGER ||
    param === Number.MIN_SAFE_INTEGER ||
    param === Number.MAX_VALUE
    // A MIN_VALUE lebegőpontosat ad(hat) vissza!
  ) {
    return true
  }

  // @ts-expect-error Itt szólna, hogy szám.
  // eslint-disable-next-line no-restricted-syntax -- Jó nekünk itt a natív.
  const x = parseFloat(param)

  return (x | 0) === x
}

const isInt = isFunction(Number.isInteger)
  ? Number.isInteger
  : isInteger

module.exports.isInteger = isInt
