const { getType } = require('./getType')

/**
 * A megadott paraméter Date típusú?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is Date} A megadott paraméter, mint Date.
 */
function isDate (param) {
  return getType(param) === 'date'
}

module.exports.isDate = isDate
