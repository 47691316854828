/**
 * Visszaadja, hogy a megadott paraméter false értékű vagy sem.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is false} A megadott érték false?
 */
function isFalsy (param) {
  return param === false
}

module.exports.isFalsy = isFalsy
