const { isBrowser } = require('./isBrowser')

/**
 * Visszaadja, hogy node környezetben vagyunk-e vagy sem.
 * @returns {boolean} Node-ban vagyunk?
 */
function isNode () {
  const isItBrowser = isBrowser()

  return !isItBrowser
}

module.exports.isNode = isNode
