const { getType } = require('./getType')

/**
 * A megadott paraméter primitív adattípusú?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is (string | number | boolean)} A paraméter primitiv?
 */
function isPrimitive (param) {
  const type = getType(param)

  if (
    type === 'string' ||
    type === 'number' ||
    type === 'boolean'
  ) {
    return true
  }

  return false
}

module.exports.isPrimitive = isPrimitive
