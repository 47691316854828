const { getType } = require('./getType')

/**
 * A megadott paraméter boolean?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is boolean} Boolean?
 */
function isBoolean (param) {
  return getType(param) === 'boolean'
}

module.exports.isBoolean = isBoolean
