const { isArray } = require('./isArray')

/**
 * A megadott tömb egyedi értékeket tartalmaz?
 * @throws Ha nem tömb, hibát dob.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {boolean} Egyedi elemeket tartalmaz?
 */
function isUniq (param) {
  if (!isArray(param)) {
    throw new TypeError('A megadott paraméter nem tömb!')
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
  //
  // Chrome: 2014
  // Edge: 2015
  // FF: 2012
  // O: 2014
  // Safari: 2014
  // node: 2015
  //

  const set = new Set(param)

  if (set.size === param.length) {
    return true
  }

  return false
}

module.exports.isUniq = isUniq
