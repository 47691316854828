const { is } = require('../../is/is')

/**
 * Visszaadja, hogy egy szám a másik számnak hány százaléka.
 * @param {number} base - A szám, amelynek a százalékát keresed.
 * @param {number} part - A szám, amely a százalékot jelenti.
 * @param {number} [fixed=2] - A tizedesjegyek száma.
 * @returns {string | number} A százalék.
 */
module.exports.getPercentage = function getPercentage (base, part, fixed = 2) {
  const res = (part / base) * 100

  if (!is.numeric(res)) {
    return 0
  }

  if (is.integer(res)) {
    return res
  }

  return res.toFixed(fixed)
}
