const { isPrimitive } = require('./isPrimitive')
const { isNullable }  = require('./isNullable')

/**
 * Megmondja, hogy a megadott paraméter Object-szerű e.
 * Ebbe beletartozik minden nem primitív vagy nullable.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is Object} A megadott paraméter valami Object-ből extendál.
 */
module.exports.isObjectLike = function isObjectLike (param) {
  if (isPrimitive(param)) {
    return false
  }

  if (isNullable(param)) {
    return false
  }

  return true
}
