const { getType } = require('./getType')

/**
 * Visszaadja, hogy a megadott paraméter object-e.
 * @template {any} T
 * @param {T} param - Az ismeretlen paraméter.
 * @returns {param is Exclude<NonNullable<T>, Array<T> | string | number | boolean | RegExp | Function>} A paraméter object?
 */
function isObject (param) {
  return getType(param) === 'object'
}

module.exports.isObject = isObject
