const { isString } = require('./isString')

/**
 * A megadott paraméter értelmezhető boolean-ként?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {boolean} A típusát nem fogjuk tudni megmondani, csak hogy igen vagy nem.
 */
module.exports.isBoolLike = function isBoolLike (param) {
  if (param === true || param === false) {
    return true
  }

  if (isString(param)) {
    const str = param.trim().toLowerCase()

    if (str === 'true' || str === 'false') {
      return true
    }
  }

  return false
}
