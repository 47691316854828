const SPACE_PRETTY = 2

const { stringify } = require('./stringify')

/**
 * Szép, 2-es indentálással stringify-ol.
 * @param {unknown} data - Az adat, amit stringify-olni akarunk.
 * @returns {string} A JSON string.
 */
module.exports.prettyStringify = function prettyStringify (data) {
  return `${ stringify(data, '{}', SPACE_PRETTY) }\n`
}
