import insertOffer from '../common/scripts/insertOffer'

import xmas        from './scripts/xmas'

xmas()

insertOffer('taneszkoz')

const idoctumLink = document.querySelector<HTMLAnchorElement>('a.list-group-item.list-group-item-action[href="https://webshop.balazs-diak.hu/idoctum-szoftvertelepitesi-utmutato"]')

if (idoctumLink) {
  idoctumLink.href = 'https://idoctum.taneszkoz.hu/pdf/idoctum_telepitesi_utmutato.pdf'
}
