const { stringify } = require('./stringify')

const { numericReplacer } = require('./numericReplacer')

const SPACE_DEFAULT = 0

 /**
  * Biztonságos stringify, biztosan egy üres objectet jelző stringet kapunk vissza.
  * A numerikus string értékeket átkonvertálja számmá.
  * @param {unknown} data - Az adat, amit stringify-olni akarunk.
  * @param {string} fallback - Fallback, ha nem sikerül valamiért.
  * @param {number} space - Mennyi space indentet akarunk.
  * @returns {string} A JSON string.
  */
module.exports.numericStringify = function numericStringify (data, fallback = '{}', space = SPACE_DEFAULT) {
  return stringify(
    data,
    fallback,
    space,
    numericReplacer
  )
}
