const { is } = require('../../is/is')

/**
 * A `value` object-ből rekurzívan kiszedi az összes kulcsot.
 * @template {Object} T
 * @param {T} value - A megadott object.
 * @param {string} prefix - Ide kerülnek a kulcsok előtti prefix-ek.
 * @param {string[]} res - Ide kerül az eddig összegyűjtött kulcsok.
 * @returns {string[]} Az összes kulcs.
 */
module.exports.getObjectKeysRecursive = function getObjectKeysRecursive (value, prefix = '', res = []) {
  for (const property in value) {
    const nextPrefix = `${ prefix }${ is.empty(prefix) ? '' : '.' }${ property }`

    const actValue = value[property]

    if (is.object(actValue)) {
      getObjectKeysRecursive(actValue, nextPrefix, res)
    }
    else {
      res.push(nextPrefix)
    }
  }

  return res
}
