/* globals $ */

import { is }    from '@common/is/is'
import { JSON }  from '@common/JSON/JSON'
import { Query } from '@common/Query/Query'

import { EOfferType }   from '@corp/incomingOfferService/backend/definitions'
import type { TSource } from '@corp/incomingOfferService/backend/definitions'

import { WEBSHOP_URLS } from '../definitions'

import getOfferFormValues from './utils/getOfferFormValues'

interface IItem {
  readonly id: number
  readonly quantity: number
}

interface ICart {
  readonly items: readonly IItem[]
}

const URL = 'https://ceg.balazs-diak.hu/ios/api/offers/insert'

/**
 * Általános és termék ajánlatkérés feldolgozás.
 * @param source - A megadott webshop.
 */
export default function insertOffer (source: TSource): void {
  const offerRequestUrl = WEBSHOP_URLS[source]

  // Általános ajánlatkérés
  $(`form[action="${ offerRequestUrl }/index.php?route=common/contact"]`)
    .on('submit', (event) => {
      const form = event.currentTarget as HTMLFormElement

      const data = getOfferFormValues(form)

      const extendedData = {
        ...data,
        source,
        type: EOfferType.General
      }

      const stringified = JSON.stringify(extendedData)

      $.ajax({
        url: URL,
        data: stringified,
        type: 'POST',
        dataType: 'json',
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': offerRequestUrl,
          'Content-Type': 'application/json'
        }
      })
    })

  $(document).ajaxComplete((_event, _xhr, options) => {
    const { url } = options

    // Nem lehet kivenni az urlt.
    if (!is.string(url)) {
      return
    }

    const decoded = Query.decode(url)

    // Termékhez tartozó árajánlatkérés
    const isProductOffer = (
      url.startsWith(`${ offerRequestUrl }/index.php?route=product/offerrequest`) &&
      is.property(decoded, 'product_id')
    )

    const isCartOffer = url === '/index.php?route=checkout/cartchildren/offerrequest/form'

    if (isProductOffer) {
      const { product_id } = decoded

      $('form#offer_req_form')
        .on('submit', (event) => {
          const form = event.currentTarget as HTMLFormElement

          const data = getOfferFormValues(form)

          const extendedData = {
            ...data,
            source,
            type: EOfferType.Product,
            products: [ { productId: product_id, quantity: 1 } ]
          }

          const stringified = JSON.stringify(extendedData)

          $.ajax({
            url: URL,
            data: stringified,
            type: 'POST',
            dataType: 'json',
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': offerRequestUrl,
              'Content-Type': 'application/json'
            }
          })
        })
    }
    // Kosár árajánlatkérés
    else if (isCartOffer) {
      $('form#offer_req_form')
        .on('submit', (event) => {
          $.ajax({
            url: `${ offerRequestUrl }/cart.json`,
            type: 'GET'
          })
            .done((cart: ICart) => {
              const products = cart.items.map((item) => {
                return {
                  productId: item.id,
                  quantity: item.quantity
                }
              })

              const form = event.currentTarget as HTMLFormElement

              const data = getOfferFormValues(form)

              const extendedData = {
                ...data,
                source,
                type: EOfferType.Cart,
                products
              }

              const stringified = JSON.stringify(extendedData)

              $.ajax({
                url: URL,
                data: stringified,
                type: 'POST',
                dataType: 'json',
                crossDomain: true,
                headers: {
                  'Access-Control-Allow-Origin': offerRequestUrl,
                  'Content-Type': 'application/json'
                }
              })
            })
        })
    }
  })
}
