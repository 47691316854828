const { getType } = require('./getType')

/**
 * @template T
 * @typedef {T extends ReadonlyArray<any> ? T : ReadonlyArray<T>} TReturnType
 */

/**
 * A megadott paraméter tömb típusú?
 * @template T
 * @param {T} param - Az ismeretlen paraméter.
 * @returns {param is TReturnType<T>} A megadott paraméter tömb.
 */
function isArray (param) {
  return getType(param) === 'array'
}

module.exports.isArray = isArray
