/* eslint-disable no-restricted-globals */

const URI = {
  /**
   * URL-ek teljes kódolására használják.
   * Az alábbi karaktereket nem kódolja: -!~*();/?:@&=+$,#'.
   * @param {string} uri - Unencoded URI.
   * @example
   * https://www.csillagsuli.hu/search?q=ugyesedni → https://www.csillagsuli.hu/search?q=ugyesedni
   * https://www.csillagsuli.hu/search?q=ugyesedni idoctum speclearn softdidactic → https://www.csillagsuli.hu/search?q=ugyesedni%20idoctum%20speclearn%20softdidactic
   * @returns {string} Az enkódolt URI.
   */
  encode: (uri) => encodeURI(uri),
  decode: decodeURI,
  /**
   * URL paraméterek kódolására használják.
   * Ez a függvény sokkal szigorúbb a karakterek kódolásában.
   * Még a speciális karaktereket is kódolja, amelyeket az encodeURI nem kódol.
   * Az alábbi karaktereket ez sem kódolja: -!~*()'.
   * @param {string | number | boolean} uriComponent - Unencoded URI component.
   * @example
   * https://www.csillagsuli.hu/search?q=ugyesedni → https%3A%2F%2Fwww.csillagsuli.hu%2Fsearch%3Fq%3Dugyesedni
   * https://www.csillagsuli.hu/search?q=ugyesedni idoctum speclearn softdidactic → https%3A%2F%2Fwww.csillagsuli.hu%2Fsearch%3Fq%3Dugyesedni%20idoctum%20speclearn%20softdidactic
   * @returns {string} Az enkódolt URI komponens.
   */
  encodeComponent: (uriComponent) => encodeURIComponent(uriComponent),
  decodeComponent: decodeURIComponent,
  /**
   * A megadott string encode-olva van?
   * @param {string} param - A string paraméter.
   * @returns {boolean} Enkódolva van?
   */
  isEncoded: (param) => {
    return decodeURIComponent(param) !== param
  }
}

module.exports.URI = URI
