/**
 * @typedef {import('./definitions').TWebshop} TWebshop
 * @typedef {import('./definitions').TNonBdidactWebshop} TNonBdidactWebshop
 */

/**
 * Az összes webshopunk listája.
 *
 * Ezen a sorrenden SOHA nem szabad változtatni, mert az a Promise.all-ban lévő mappokban és egyéb helyeken
 * más sorrendet eredményezne és beláthatatlan következményekkel járna!
 * @type {readonly TWebshop[]}
 */
const WEBSHOPS = [
  'taneszkoz', // 1.
  'bdovi',     // 2.
  'bdro'       // 3.
]

/**
 * A BDidact-ot nem tartalmazó webshopjaink listája.
 * @type {readonly TNonBdidactWebshop[]}
 */
const NON_BDIDACT_WEBSHOPS = [
  'taneszkoz', // 1.
  'bdovi'      // 2.
]

/**
 * @readonly
 * @enum {number}
 */
const PRODUCT_STATUS = {
  /** Engedélyezett. */
  Enabled: 1,

  /** Letiltott. */
  Banned: 0,

  /** Kifutott. */
  RanOut: 2
}

/**
 * @readonly
 * @enum {number}
 */
const PRODUCT_ORDERABLE = {
  /** Ez az admin "Rendelhető" néven fut. */
  Purchasable:    1,

  /** Ez rendesen "Nem megvásárolható" az adminon. */
  NotPurchasable: 0,

  /** Ajánlatkérés. */
  Offer:          3,

  /** Megrendelem. */
  Orderable:      4
}

/**
 * @readonly
 * @enum {number}
 */
const CATEGORY_STATUS = {
  Banned: 0,
  Enabled: 1
}

module.exports = {
  WEBSHOPS,
  NON_BDIDACT_WEBSHOPS,
  PRODUCT_STATUS,
  PRODUCT_ORDERABLE,
  CATEGORY_STATUS
}
