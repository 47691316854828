const { Object } = require('../../Object/Object')
const { URI } = require('../../URI/URI')
const { is } = require('../../is/is')

/** @typedef {import('../definitions').IQueryParams} IQueryParams */

/**
 * Egy objectből querydata készítés.
 * Query-ben lehet kulcs úgy, hogy nincs mellette érték, ezért van engedélyezve az undefined value.
 * @example
 * http_url = "http:" "//" host [ ":" port ] [ abs_path [ "?" query ]]
 * @see {@link https://www.rfc-editor.org/rfc/rfc3986#section-3.4}
 * @param {IQueryParams} data - Az object, amiből a query lesz.
 * @returns {string} A kész query string.
 */
module.exports.encode = function encode (data) {
  const encoded = Object
    .getKeys(data)
    .map((key) => {
      const encodedKey = URI.encodeComponent(key)
      const rawValue   = data[key]

      // Ilyen esetben nem tartozik érték az adott kulcshoz.
      if (!is.defined(rawValue)) {
        return encodedKey
      }

      // Ekkor a kulcshoz egy primitív érték tartozik.
      if (!is.array(rawValue)) {
        return `${ encodedKey }=${ URI.encodeComponent(rawValue) }`
      }

      // Különben pedig egy tömbről beszélünk,
      // és ilyenkor minden benne lévő elemet
      // enkódolva felveszünk a megadott kulcshoz.
      //
      // Pl: { array: [1, 2, 3 ]} => array[]=1&array[]=2&array[]=3
      const mappedValues = is.empty(rawValue)
        ? [ `${ encodedKey }[]=` ]
        : rawValue.map((value) => `${ encodedKey }[]=${ URI.encodeComponent(value) }`)

      return mappedValues.join('&')
    })
    .join('&')

  return encoded
}
