const { getKeys } = require('./getKeys')

/**
 * Visszaadja egy object kulcsait egy megadott érték alapján.
 * @example
 * const obj = { a: 1, b: 2, c: 3, d: 3 }
 * const keys = getKeysByValue(obj, 3) // [ 'c', 'd' ]
 * @template {string} K
 * @param {Partial<TReadonlyRecord<K, unknown>>} object - Az objektum, aminek a kulcsait szeretnénk elkérni.
 * @param {unknown} value - Az érték, ami alapján keressük a kulcsokat.
 * @returns {readonly K[]} A kulcsokat tartalmazó, típusbiztos tömb.
 */
module.exports.getKeysByValue = function getKeysByValue (object, value) {
  const allKeys = getKeys(object)

  const keys = allKeys.reduce((/** @type {K[]} */ acc, curr) => {
    if (object[curr] === value) {
      acc.push(curr)
    }

    return acc
  }, [])

  return keys
}
