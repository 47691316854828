const { isNonNullable } = require('./utils/isNonNullable')
const { isInstanceOf }  = require('./utils/isInstanceOf')
const { isObjectLike }  = require('./utils/isObjectLike')
const { isPrimitive }   = require('./utils/isPrimitive')
const { isUndefined }   = require('./utils/isUndefined')
const { isFunction }    = require('./utils/isFunction')
const { isInfinite }    = require('./utils/isInfinite')
const { isIncludes }    = require('./utils/isIncludes')
const { isBoolLike }    = require('./utils/isBoolLike')
const { isNullable }    = require('./utils/isNullable')
const { isProperty }    = require('./utils/isProperty')
const { isNumeric }     = require('./utils/isNumeric')
const { isDefined }     = require('./utils/isDefined')
const { isInteger }     = require('./utils/isInteger')
const { isBoolean }     = require('./utils/isBoolean')
const { isBrowser }     = require('./utils/isBrowser')
const { isRegExp }      = require('./utils/isRegExp')
const { isNumber }      = require('./utils/isNumber')
const { isObject }      = require('./utils/isObject')
const { isString }      = require('./utils/isString')
const { isTruthy }      = require('./utils/isTruthy')
const { isBuffer }      = require('./utils/isBuffer')
const { isFalsy }       = require('./utils/isFalsy')
const { isFloat }       = require('./utils/isFloat')
const { isArray }       = require('./utils/isArray')
const { isEmpty }       = require('./utils/isEmpty')
const { isPrime }       = require('./utils/isPrime')
const { isDate }        = require('./utils/isDate')
const { isNode }        = require('./utils/isNode')
const { isUniq }        = require('./utils/isUniq')
const { isEven }        = require('./utils/isEven')
const { isNull }        = require('./utils/isNull')
const { isOdd }         = require('./utils/isOdd')

const is = {
  instanceOf: isInstanceOf,
  regexp: isRegExp,
  undefined: isUndefined,
  boolLike: isBoolLike,
  primitive: isPrimitive,
  includes: isIncludes,
  property: isProperty,
  buffer: isBuffer,
  objectLike: isObjectLike,
  nonNullable: isNonNullable,
  nullable: isNullable,
  infinite: isInfinite,
  function: isFunction,
  numeric: isNumeric,
  defined: isDefined,
  boolean: isBoolean,
  integer: isInteger,
  browser: isBrowser,
  number: isNumber,
  object: isObject,
  string: isString,
  truthy: isTruthy,
  float: isFloat,
  falsy: isFalsy,
  empty: isEmpty,
  array: isArray,
  prime: isPrime,
  date: isDate,
  node: isNode,
  null: isNull,
  even: isEven,
  uniq: isUniq,
  odd: isOdd
}

module.exports.is = is
