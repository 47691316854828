const { getType } = require('./getType')

const FUNCTION_TYPES = [
  'function',
  'asyncfunction'
]

/**
 * Visszaadja, hogy a megadott paraméter függvény-e.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is Function} Függvény?
 */
function isFunction (param) {
  return FUNCTION_TYPES.includes(getType(param))
}

module.exports.isFunction = isFunction
