/**
 * Visszaadja, hogy az adott paraméter véges (finite) e.
 * @link {@see https://github.com/airbnb/javascript#standard-library--isfinite}
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is number} Véges?
 */
function isFinite (param) {
  return Number.isFinite(param)
}

module.exports.isFinite = isFinite
