const { isFinite } = require('./isFinite')

/**
 * A megadott paraméter BIZTONSÁGOS szám típusú?
 * Az Infinity és a NaN is szám volna a JS-nél, de a mi világunkban nem az.
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is number} A paraméter number?
 */
function isNumber (param) {
  return isFinite(param)
}

module.exports.isNumber = isNumber
