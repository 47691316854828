/* globals globalThis */

const { isString }     = require('./isString')
const { isNode }       = require('./isNode')
const { isFunction }   = require('./isFunction')
const { isNumber }     = require('./isNumber')
const { isInstanceOf } = require('./isInstanceOf')
const { isBuffer }     = require('./isBuffer')

/**
 * A megadott paraméter arrayLike?
 * @param {unknown} param - Az ismeretlen paraméter.
 * @returns {param is Record<'length', number>} A megadott paraméter tömb.
 */
function isArrayLike (param) {
  /**
   * NodeList nincs node környezetben.
   * @type {any}
   */
  const NodeList = isNode() ? undefined : globalThis.NodeList

  if (
    !isInstanceOf(param, Object) &&
    !isBuffer(param) &&
    !isInstanceOf(param, NodeList) &&
    // A String nem Objectből extendál, de a mi világunkban ArrayLike.
    !isString(param)
  ) {
    return false
  }

  if (isFunction(param)) {
    return false
  }

  // @ts-ignore
  // eslint-disable-next-line sonarjs/no-collection-size-mischeck, custom/no-empty-length
  return isNumber(param.length) && param.length >= 0
}

module.exports.isArrayLike = isArrayLike
