const { getKeys } = require('./getKeys')

/**
 * Megcseréli object-nek a kulcs-érték párjait.
 * @template {Object} T
 * @param {T} object - A megadott object.
 * @returns {import('../definitions').TInvertObject<T>} Az invertált object.
 */
module.exports.invert = function invert (object) {
  /** @type {TUnknownObject} */
  const obj = {}

  getKeys(object).forEach((key) => {
    const value = object[key]
    // @ts-expect-error Jó az, hagyjál TS!
    obj[value] = key
  })

  /** @type {any} */
  const res = obj

  return res
}
