const { isObjectLike } = require('./isObjectLike')

/**
 * A megadott paraméter tartalmazza-e a megadott kulcsot.
 * @template {string | number | symbol} T
 * @param {unknown} param - Az ismeretlen paraméter.
 * @param {T} key - A kulcs.
 * @returns {param is Record<T, unknown>} A megadott paraméterben létezik a kulcs.
 */
function isProperty (param, key) {
  if (isObjectLike(param)) {
    return key in param
  }

  return false
}

module.exports.isProperty = isProperty
