const { isNull } = require('./isNull')
const { isUndefined } = require('./isUndefined')

/**
 * Visszaadja, hogy a megadott paraméter null vagy undefined-e.
 * @template {unknown} T
 * @param {T} param - Az ismeretlen paraméter.
 * @returns {T is null | undefined} A paraméter null vagy undefined.
 */
module.exports.isNullable = function isNullable (param) {
  return isNull(param) || isUndefined(param)
}
