const { random }                      = require('./utils/random')
const { randomExcept }                = require('./utils/randomExcept')
const { parse }                       = require('./utils/parse')
const { gokuParseInt }                = require('./utils/gokuParseInt')
const { gokuParseFloat }              = require('./utils/gokuParseFloat')
const { half }                        = require('./utils/half')
const { getPercentage }               = require('./utils/getPercentage')
const { double }                      = require('./utils/double')
const { degreeBetweenTwoCoordinates } = require('./utils/degreeBetweenTwoCoordinates')
const { distanceBetweenTwoPosition }  = require('./utils/distanceBetweenTwoPosition')

/**
 * Number parse.
 * @constructor
 * @param  {any[]} args - Args.
 * @returns {number} Parse-olt number.
 */
function GokuNumber (...args) {
  // eslint-disable-next-line no-restricted-globals, no-restricted-syntax
  return Number(...args)
}

GokuNumber.getPercentage  = getPercentage
GokuNumber.half           = half
GokuNumber.double         = double
GokuNumber.random         = random
GokuNumber.randomExcept   = randomExcept
GokuNumber.parse          = parse
GokuNumber.parseInt       = gokuParseInt
GokuNumber.parseFloat     = gokuParseFloat
GokuNumber.degreeBetweenTwoCoordinates = degreeBetweenTwoCoordinates
GokuNumber.distanceBetweenTwoPosition  = distanceBetweenTwoPosition
/* eslint-disable no-restricted-globals */
GokuNumber.MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER
GokuNumber.MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER
/* eslint-enable no-restricted-globals */

module.exports.Number = GokuNumber
