/**
 * Alapértelmezett parse reviver, ahol a speciális értékeket alakítjuk át.
 * @param {unknown} _key - Nem használt kulcs.
 * @param {unknown} value - Az ismeretlen érték.
 * @returns {any} A visszaadandó érték.
 */
module.exports.defaultParseReviver = function defaultParseReviver (_key, value) {
  if (value === 'Infinity') {
    // eslint-disable-next-line no-restricted-globals
    return Infinity
  }

  if (value === '-Infinity') {
    // eslint-disable-next-line no-restricted-globals
    return -Infinity
  }

  return value
}
